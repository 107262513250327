<template>
  <transition name="fadeIn" mode="in-out">
    <li v-show="!isLoading" class="mb-10 xxl:mb-20 px-5 xxl:px-10 w-full md:w-1/2 lg:w-1/3">
      <template v-if="item.image && !!item.image[0]">
        <div class="article-card card h-full flex flex-col flex-grow move-up transition-normal-transform transition shadow hover:shadow-md cursor-pointer">
          <article class="article-card__content bg-white rounded flex flex-col h-full shadow">
            <figure class="article-card__image relative aspect-ratio-box aspect-ratio-box--8-5 object-fit-fallback">
              <img
                class="lazyload absolute top-0 left-0 z-0 w-full h-full block object-cover rounded rounded-b-none"
                :src="!!item.image[0] ? item.image[0].imageOptimize.placeholder : '/assets/img/martha_recipe_placeholder.jpg'"
                :data-srcset="!!item.image[0] ? item.image[0].imageOptimize.optimizedImageUrls.join(', ') : '/assets/img/martha_recipe_placeholder.jpg'"
                data-sizes="auto"
                sizes="(min-width: 720px) 25vw, 100vw"
                alt=""
              >
            </figure>

            <div class="article-card__meta px-12 pb-8 pt-12 extra-small-text">
              <template v-if="!!categories">
                {{_.map(categories.slice(0,2), 'title').join(', ')}}
              </template>
              <time class="extra-small-text opacity-65" :class="!!categories && categories.length > 0 ? 'pl-5' : ''" :datetime="formatDate(item.postDate, 'date')">{{ formatDate(item.postDate, 'date') }}</time>

            </div>

            <h2 class="article-card__title mb-none px-12 heading-4">
              <a :href="item.url">{{ item.title }}</a>
            </h2>

            <footer aria-hidden="true" class="article-card__footer px-12 pb-12 pt-16 mt-auto mb-none flex justify-between items-center small-text text-textPrimaryColor group hover:cursor-pointer">
              <span>{{ 'Read more'|t }}</span>
              <span class="pl-3 group-hover:transform-right text-textPrimaryColor">
                <svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768">
                  <path d="M667.859 357.544l-242.748-242.748 39.104-39.104 303.784 303.784-312.832 312.832-37.973-37.973 243.089-243.089h-660.284v-53.702h667.86z"></path>
                </svg>
              </span>
            </footer>
          </article>
        </div>
      </template>
      <template v-else>
        <div class="article-card card block h-full group flex flex-col flex-grow move-up transition-normal-transform transition shadow hover:shadow-md cursor-pointer">
          <article class="article-card__content rounded shadow flex flex-col h-full no-image" :class="[bgColor, textColor]">
            <div class="article-card__meta px-12 pb-8 pt-12 extra-small-text">
              <template v-if="!!categories">
                {{_.map(categories.slice(0,2), 'title').join(', ')}}
              </template>
              <time :class="!!categories && categories.length > 0 ? 'pl-5' : ''" :datetime="formatDate(item.postDate, 'date')">{{ formatDate(item.postDate, 'date') }}</time>
            </div>

            <h2 class="article-card__title mb-none px-12 pb-9 heading-4" :class="textColor">
              <a :href="item.url">{{ item.title }}</a>
            </h2>

            <footer aria-hidden="true" class="article-card__footer px-12 pb-12 pt-16 mt-auto mb-none flex justify-between items-center small-text group hover:cursor-pointer" :class="textColor">
              <span>{{ 'Read more'|t }}</span>
              <span class="pl-3 group-hover:transform-right" :class="textColor">
                <svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768">
                  <path d="M667.859 357.544l-242.748-242.748 39.104-39.104 303.784 303.784-312.832 312.832-37.973-37.973 243.089-243.089h-660.284v-53.702h667.86z"></path>
                </svg>
              </span>
            </footer>
          </article>
        </div>
      </template>
    </li>
  </transition>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

import 'lazysizes/plugins/attrchange/ls.attrchange';
import {translation} from '../js/search/translation';

const arrowRight = '<span class="pl-3 group-hover:transform-right text-textPrimaryColor"><svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768"> <path d="M667.859 357.544l-242.748-242.748 39.104-39.104 303.784 303.784-312.832 312.832-37.973-37.973 243.089-243.089h-660.284v-53.702h667.86z"></path></svg></span>'
const colorCodes = {
  'Maaseutu-saaristo': ['pickledBluewood', 'pickledBluewood', 'orient', 'ming', 'aquaForest', 'white'],
  'Maaseutupolitiikka': ['pickledBluewood', 'white'],
  'Saaristopolitiikka': ['pickledBluewood', 'orient', 'ming', 'aquaForest', 'white'],
  'Maaseutuparlamentti': ['parlamenttiBlue', 'parlamenttiBlueLight', 'parlamenttiGreen', 'white']
}

export default {
  name: 'CategorySearchItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    sectionType: {
      type: Array,
      default: []
    },
    siteGroup: {
      type: String
    },
    categories: {
      type: Array,
      defailt: null
    }
  },
  data() {
    return {
      bgColor: 'bg-pickledBluewood'
    }
  },
  created: function() {
    const choosenBgColor = colorCodes[this.siteGroup].random();
    this.bgColor = `bg-${choosenBgColor || 'pickledBluewood'}`
  },
  beforeUpdate: function() {
  },
  mounted: function() {
    // CSS object-fit fallback on Edge (and other browsers)
    // Read more, https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3

    // Detect objectFit support
    if ('objectFit' in document.documentElement.style === false) {

      // assign HTMLCollection with parents of images with objectFit to variable
      const container = document.getElementsByClassName('object-fit-fallback')

      // Loop through HTMLCollection
      for (let i = 0; i < container.length; i++) {
        // Asign image source to variable

        if (container[i].querySelector('img') !== null) {
          let imageSource = container[i].querySelector('img').src


          if (
            container[i].querySelector('img').getAttribute('data-srcset') !== null
          ) {
            const firstSrcSet = container[i]
              .querySelector('img')
              .getAttribute('data-srcset')
              .split(', ')[0]
            imageSource = firstSrcSet.split(' ')[0]
          }

          // Hide image
          container[i].querySelector('img').style.display = 'none'

          // Add background-size: cover
          container[i].style.backgroundSize = 'cover'

          // Add background-image: and put image source here
          container[i].style.backgroundImage = 'url(' + imageSource + ')'

          // Add background-position: center center
          container[i].style.backgroundPosition = 'center center'
        }
      }
    }
    this.focusStyle();
  },
  beforeDestroy: function() {
    this.removeFocusStyle();
  },
  watch: {
    isLoading: function(val, oldVal) {
      if(!val && oldVal) {
        const choosenBgColor = colorCodes[this.siteGroup].random();
        this.bgColor = `bg-${choosenBgColor || 'pickledBluewood'}`
      }
    }
  },
  filters: {
    t(val) {
      const locale = document.getElementById('searchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  computed: {
    textColor: function() {
      return this.bgColor === ('bg-white') ? 'text-black' : 'text-white'
    },
    _(){
      return _;
    }
  },
  methods: {
    formatDate: function(date, format) {
      const momentDate = moment.unix(date);

      switch (format) {
        case 'time':
          return momentDate.format('HH:mm');
        break;
        case 'day':
          return momentDate.format('dddd');
        break;
        case 'date':
          return momentDate.format('DD.MM.YYYY');
        break;
      }
    },
    focusStyle() {
      // Focus style
      const elements = this.$el.querySelectorAll("a, button, input[type='button'], select");
      let mouseDown = false;

      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('mousedown', () => {
          mouseDown = true;
        });

        elements[i].addEventListener('mouseup', () => {
          mouseDown = false;
        });

        elements[i].addEventListener('focus', (event) => {
          if (mouseDown) {
            event.target.classList.add('mouseDown');
          }
        });
        elements[i].addEventListener('blur', (event) => {
          event.target.classList.remove('mouseDown');
        });
      }
    },
    removeFocusStyle() {
      // Focus style
      const elements = this.$el.querySelectorAll("a, button, input[type='button'], select");
      let mouseDown = false;

      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('mousedown', () => {
          mouseDown = true;
        });

        elements[i].removeEventListener('mouseup', () => {
          mouseDown = false;
        });

        elements[i].removeEventListener('focus', (event) => {
          if (mouseDown) {
            event.target.classList.add('mouseDown');
          }
        });
        elements[i].removeEventListener('blur', (event) => {
          event.target.classList.remove('mouseDown');
        });
      }
    }
  }
};
</script>
