// We import all other js libraries
import Vue from 'vue'
import './externalLinks'

const vues = document.querySelectorAll(".toggleContent");
Array.prototype.forEach.call(vues, (el, index) => new Vue({
  el,
  data:{show: false},
  mounted() {
    if(el.dataset.show) {
      this.show = true;
    }
    this.focusStyle();
  },
  methods: {
    focusStyle() {
      // Focus style
      const elements = this.$el.querySelectorAll("a, button, input[type='button'], select");
      let mouseDown = false;

      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('mousedown', () => {
          mouseDown = true;
        });

        elements[i].addEventListener('mouseup', () => {
          mouseDown = false;
        });

        elements[i].addEventListener('focus', (event) => {
          if (mouseDown) {
            event.target.blur();
          }
        });
      }
    }
  }
}))
