<template>
  <nav class="block content-wrapper mx-auto pt-10" :aria-label="Pagination|t">
    <ul v-if="pages > 1" class="pt-6 list-reset flex items-center justify-center small-text line-height-none">
      <li :class="current > 1 ? 'text-blue cursor-pointer transform-left' : 'text-blue opacity-30'" class="flex items-center" @click="current > 1 ? changePage(current - 1) : ''" @keyup.enter="current > 1 ? changePage(current - 1, true) : ''" :tabindex="current > 1 ? 0 : -1" :aria-label="'Previous page'|t">
        <span class="pr-6 inline-block">
          <svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768">
            <path d="M96.735 410l241.085 241.085-39.27 39.27-298.55-298.55 314.161-314.161 37.318 37.319-242.261 242.261h658.781v52.777h-671.265z"></path>
          </svg>
        </span>
      </li>
      <template v-for="n in (current - 2, current + 2)">
        <li :class=" n !== current ? 'cursor-pointer' : ''" :key="n" v-if="n > 0 && n >= current - 2 && n <= pages" @click="changePage(n)" @keyup.enter="changePage(n, true)" tabindex="0" :aria-label="'Page {number}'|t({number: n})">
          <span :class=" n === current ? 'mx-5-5 flex items-center justify-center w-22-5 h-22-5 inline-block bg-white rounded-full text-ebonyClay shadow' : 'px-10 text-ebonyClay hover:opacity-50' " :rel="n > current ? 'next' : n < current ? 'prev' : false" class="">{{ n }}</span>
        </li>
      </template>
      <li :class="current < pages ? 'cursor-pointer text-blue transform-right' : 'text-blue opacity-30'" class="flex items-center " @click="current < pages ? changePage(current + 1) : ''" @keyup.enter="current < pages ? changePage(current + 1, true) : ''" :tabindex="current < pages ? 0 : -1" :aria-label="'Next page'|t">
        <span class="pl-6 inline-block">
          <svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768">
            <path d="M667.859 357.544l-242.748-242.748 39.104-39.104 303.784 303.784-312.832 312.832-37.973-37.973 243.089-243.089h-660.284v-53.702h667.86z"></path>
          </svg>
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
import _ from 'lodash'
import {translation} from '../js/search/translation'

export default {
  props: {
    pages: {
      type: Number,
      default: 1,
    },
    current: {
      type: Number,
      default: 1,
    }
  },
  mounted: function() {
    this.focusStyle();
  },
  beforeDestroy: function() {
    this.removeFocusStyle();
  },
  filters: {
    t(val, params = {}) {
      const locale = MMM.language || 'fi-FI';
      const trans = translation[locale];
      let string = trans[val] || val;

      _.each(params, (value, key) => {
        let regex = new RegExp('{' + key + '}');
        string = string.replace(regex, value);
      });

      return string;
    },
  },
  methods: {
    changePage(page, keyboardAccess) {
      this.$emit('update:current', page)
      if(keyboardAccess) {
        this.$el.parentElement.querySelector('#search-results a:first-of-type').focus();
      }
    },
    focusStyle() {
      // Focus style
      const elements = this.$el.querySelectorAll("a, button, input[type='button'], select");
      let mouseDown = false;

      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('mousedown', () => {
          mouseDown = true;
        });

        elements[i].addEventListener('mouseup', () => {
          mouseDown = false;
        });

        elements[i].addEventListener('focus', (event) => {
          if (mouseDown) {
            event.target.classList.add('mouseDown');
          }
        });
        elements[i].addEventListener('blur', (event) => {
          event.target.classList.remove('mouseDown');
        });
      }
    },
    removeFocusStyle() {
      // Focus style
      const elements = this.$el.querySelectorAll("a, button, input[type='button'], select");
      let mouseDown = false;

      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('mousedown', () => {
          mouseDown = true;
        });

        elements[i].removeEventListener('mouseup', () => {
          mouseDown = false;
        });

        elements[i].removeEventListener('focus', (event) => {
          if (mouseDown) {
            event.target.classList.add('mouseDown');
          }
        });
        elements[i].removeEventListener('blur', (event) => {
          event.target.classList.remove('mouseDown');
        });
      }
    }
  },
}
</script>
