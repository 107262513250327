<template>
  <div>
    <FunctionalCalendar
      v-model="calendarData"
      v-slot:default="props"
      :configs="calendarConfigs"
      :change-month-function="true"
      @changedMonth="changedMonth"
      ref="Calendar"
      class="calendar"
    >
      <div
        tabindex='0'
        class="day-inner z-10"
        :class="{'cursor-pointer': !!checkIfEvent(props.day.date)}"
        :datetime="props.day.date"
        :aria-label="dayAriaLabel(props)"
      >
        <div class="number">
          {{ props.day.day }}
        </div>
        <span
          v-for="(event, key) in checkIfEvent(props.day.date)"
          :key="key"
          :class="event"
          class="dot"
        ></span>
      </div>
    </FunctionalCalendar>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from "moment";
import tz from 'moment-timezone';
import { FunctionalCalendar } from 'vue-functional-calendar';
import { t } from '../js/search/translation';

let timerid;

export default {
    components: {
        FunctionalCalendar
    },
    props: {
      'eventDates': {
        stype: Array,
        default: []
      },
      'startDate': {
        type: String,
        default: moment().startOf('month').format('X')
      },
      'endDate': {
        type: String,
        default: moment().endOf('month').format('X')
      },
      'selectedDate': {
        type: String,
        default: ''
      },
      'calendarDate': {
        type: Object,
        default: () => ({
          selectedDate: {
            type: String
          }
        })
      },
      'calendarHeight': {
        type: Number,
        default: 101
      },
      'hasEventOnSelectedDay': {
        type: Boolean,
        default: true
      },
      'locale': {
        type: String
      }
    },
    data() {
      return {
        calendarData: {},
        calendarConfigs: {
          sundayStart: false,
          dateFormat: 'yyyy-mm-dd',
          isDatePicker: true,
          isDateRange: false,
          monthNames: moment.localeData(this.locale).months(),
          dayNames: moment.localeData(this.locale).weekdays(),
          // applyStylesheet: false
        },
      }
    },
    watch: {
      "calendarData.selectedDate": function(val) {
        if(!!val) {
          const selectedDate = moment(val, "YYYY-MM-DD").format('X');
          this.$emit('update:selected-date', selectedDate);
          this.$emit('update:has-event-on-selected-day', !!this.eventDates[val]);
        }
      }
    },
    mounted: function(){
      this.insertSeeAllEventsInSelectedMonth();
      this.getCalendarHeight();
      this.focusSingleDate();
    },
    methods: {
      insertSeeAllEventsInSelectedMonth() {
        const target = document.getElementsByClassName('vfc-cursor-pointer')[1];

        // Create the new element
        // This can be any valid HTML element: p, article, span, etc...
        var div = document.createElement('button');

        // Add content to the new element
        div.innerHTML = 'See all events in the selected month';
        div.style.zIndex = 200;
        div.classList.add("button", "button-small", "button-green", "hide-from-screen", "showEvents");
        target.parentNode.insertBefore( div, target.nextSibling );

      },
      focusSingleDate() {
        const self = this;
        let selectedDate = moment().format("YYYY-MM-DD");
        document.getElementById("eventCalendar").addEventListener("keyup", (event) => {
            if(event.keyCode==9){
              //enter key was pressed
              selectedDate = event.target.getAttribute("datetime");
            }
            if(event.keyCode==13 || event.keyCode==32){
              event.preventDefault();
              //enter key was pressed
              if(event.target.classList.contains('vfc-arrow-left')) {
                self.$refs.Calendar.PreMonth();
              } else if(event.target.classList.contains('vfc-arrow-right')) {
                self.$refs.Calendar.NextMonth();
              } else if(event.target.classList.contains('backToCalendar')) {
                document.querySelector(".day-inner").focus();
              } else if(event.target.classList.contains('showEvents')) {
                document.querySelector(".calenderListIndex").focus();
              } else {
                self.$refs.Calendar.ChooseDate(selectedDate);
                self.$refs.Calendar.$data.input.selectedDate = selectedDate;
                self.$refs.Calendar.value.selectedDate = selectedDate;
                document.querySelector(".calenderListIndex").focus();
              }
            }
        });
      },
      changedMonth(payload) {
        const formated = moment.tz(payload, 'Europe/Helsinki').format();

        const selectedStartDate = moment(formated).startOf('month').format('X');
        const selectedEndDate = moment(formated).endOf('month').format('X');
        const selectedDate = '';

        this.$emit('update:start-date', selectedStartDate);
        this.$emit('update:end-date', selectedEndDate);
        this.$emit('update:selected-date', selectedDate);
        this.getCalendarHeight();
      },
      checkIfEvent(calendarDate) {
        const self = this;

        if(!!self.eventDates[calendarDate]) {
          return _.uniq(self.eventDates[calendarDate]);
        }
      },
      getCalendarHeight() {
        if (timerid) {
          clearTimeout(timerid);
        }

        timerid = setTimeout(() => {
          let height = this.$refs.Calendar.$refs.day[0].parentElement.parentElement.clientHeight - 5;
          this.$emit('update:calendar-height', height);
        }, 800);
      },

      dayAriaLabel(props) {
        let numberOfEvents = !!this.checkIfEvent(props.day.date) ? this.eventDates[props.day.date].length : 0;
        let date = new Date(props.day.date)
          .toLocaleString(MMM.language, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          });

        if (numberOfEvents == 1) {
          return t('{numberOfEvents} event, {date}', {numberOfEvents: numberOfEvents, date: date});
        } else {
          return t('{numberOfEvents} events, {date}', {numberOfEvents: numberOfEvents, date: date});
        }
      }
    }
}
</script>
<style lang="scss" scoped>

  $font-family: Gotham-Narrow-Book, Helvetica, sans-serif;
  $border-radius: 4px;

  .calendar {
    &::v-deep {
      .vfc-main-container {
        @apply bg-transparent;
        @apply shadow-none;
        @apply mb-10;
      }
      .vfc-navigation-buttons {
        @apply mt-0 #{!important};
        @apply py-16 #{!important};
        @apply px-10 #{!important};
        .vfc-cursor-pointer {
          @apply py-0;
          @apply px-15;
          @apply m-0;
          .vfc-arrow-left {
            @apply w-14;
            @apply h-14;
            @apply m-0 #{!important};
            @apply border;
            @apply border-lightGray;
            @apply rounded-full;
            @apply flex;
            @apply items-center;
            @apply justify-center;
            &:after {
              content: "";
              @apply absolute;
              @apply border-grayDarker;
              @apply border-t;
              @apply border-l;
              @apply w-2-5;
              @apply h-2-5;
              right: 10px;
              top: 11px;
            }
          }
          .vfc-arrow-right {
            @apply w-14;
            @apply h-14;
            @apply m-0 #{!important};
            @apply border;
            @apply border-lightGray;
            @apply rounded-full;
            @apply flex;
            @apply items-center;
            @apply justify-center;
            &:after {
              content: "";
              @apply absolute;
              @apply border-grayDarker;
              @apply border-t;
              @apply border-r;
              @apply w-2-5;
              @apply h-2-5;
              left: 10px;
              top: 11px;
            }
          }
        }
      }
      .vfc-underline {
        @apply no-underline #{!important};
      }
      .vfc-content {
        @apply mr-0 ml-0 #{!important};
        @screen lg {
          @apply mr-10 #{!important};
        }
        .vfc-top-date {
          @apply capitalize;
          @apply bg-white;
          @apply m-0 #{!important};
          @apply p-17;
          @apply rounded;
          @apply text-textPrimaryColor;
          @apply font-sans;
          @apply text-base #{!important};
          @apply shadow;

          padding: 2rem 1.25rem !important;
          line-height: 1.75em;
        }
        .vfc-dayNames {
          @apply m-0;
          @apply p-0;
          @apply pt-11;
          @apply hidden #{!important};
          @screen md {
            @apply flex #{!important};
          }
          span {
            @apply capitalize;
            @apply text-white #{!important};
            @apply font-sans-medium;
            @apply text-xs;
            @apply mr-0 #{!important};
            @apply text-left #{!important};
          }
        }
        .vfc-week {
          @apply my-0 #{!important};
          @apply -mx-1-5 #{!important};
          @apply hidden #{!important};
          @screen md {
            @apply flex #{!important};
          }
        }
        .vfc-day {
          @apply m-1-5 #{!important};
        }
        .vfc-span-day {
          @apply w-full #{!important};
          @apply m-0 #{!important};
          @apply shadow;
          @apply rounded #{!important};
          @apply bg-white;
          @apply overflow-hidden;
          &.vfc-today {
            @apply bg-white #{!important};
          }
          &.vfc-hover:hover {
            @apply bg-lightGray #{!important};
          }
          &.vfc-marked {
            &:before {
              @apply bg-lightGray #{!important};
              @apply rounded;
            }
          }
          &:before {
            content: "";
            @apply block;
            @apply w-full;
            @apply pt-full;
          }
          &.vfc-hide {
            @apply bg-opacity-70;
          }
          &.vfc-today {

          }
          .day-inner {
            @apply absolute;
            @apply top-0;
            @apply left-0;
            @apply w-full;
            @apply h-full;
            .number {
              @apply p-7;
              @apply text-xs;
              @apply leading-none;
              @apply text-right;
              @apply text-grayDark;
              @apply font-sans-light;
            }
          }
        }
      }
    }
  }
  .dot {
    position: absolute;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    bottom: 5px;
    @screen md {
      width: 10px;
      height: 10px;
      bottom: 10px;
    }
    &:nth-child(2) {
      left: 5px;
      @screen md {
        left: 10px;
      }
    }
    &:nth-child(3) {
      left: 10px;
      @screen md {
        left: 16px;
      }
    }
    &:nth-child(4) {
      left: 15px;
      @screen md {
        left: 22px;
      }
    }
  }
  .maaseutuSaaristo, .ruralArchipelago, .landsbygdsSkargard {
    background-color: #E4EBF0;
  }
  .maaseutuparlamentti, .ruralParliament, .landsbygdsparlamentet {
    background-color: #3E6D95;
  }
  .maaseutupolitiikka, .ruralPolicy, .landsbygdspolitik {
    background-color: #529dff;
  }
  .saaristopolitiikka, .islandPolicy, .skargardspolitiken {
    background-color: #73B080;
  }
</style>
