<template>
  <div :style="{ minHeight: isLoading ? listMinHeight() : 'auto' }">
    <div class="mb-10 pb-3 border-b border-1 border-solid border-gallery"></div>
    <div class="relative mx-auto min-h-md">
      <div class="absolute w-full h-full" style="z-index: -1">
        <search-page-loading-indicator
          v-for="n in 3"
          :key="n"
          :is-loading="isLoading"
          :is-ready="isReady"
        ></search-page-loading-indicator>
      </div>
      <template v-if="items.length > 0">
        <ul class="-mx-5 xxl:-mx-10 flex flex-wrap">
          <search-page-item
            v-for="(item, key) in items"
            :key="key"
            :item="item"
            :is-loading="isLoading"
          ></search-page-item>
        </ul>
      </template>
      <transition name="fadeIn" mode="in-out">
        <template v-if="!isLoading && items.length === 0">
          <h4 class="heading-4 uppercase mx-auto py-8 text-center">{{ 'No results found'|t }}</h4>
        </template>
      </transition>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {translation} from '../js/search/translation'
import SearchPageItem from './SearchPageItem'
import SearchPageLoadingIndicator from './SearchPageLoadingIndicator';

export default {
  name: 'SearchPageList',
  props: {
    items: {
      type: Array,
      default: [],
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isReady: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SearchPageItem,
    SearchPageLoadingIndicator
  },
  filters: {
    t(val) {
      const locale = (document.getElementById('searchApp') || document.getElementById('searchPage')).dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  methods: {
    listMinHeight: function() {
      const rowHeight = 280;
      const rowAmount = 3;

      return rowAmount * rowHeight + 'px';
    }
  }
};
</script>
