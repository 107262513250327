import forEach from 'lodash/forEach';

const cards = document.querySelectorAll('.card');
handleCardClick(cards);

export default function handleCardClick(cards = []) {
    forEach(cards, (card) => {
        let down, up, link = card.querySelector('a');

        card.addEventListener('pointerdown', () => down = +new Date());
        card.addEventListener('pointerup', (e) => {
            up = +new Date();
            if ((up - down) < 200) {
                link.click();
            }
        });
    });
}
