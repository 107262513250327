import forEach from 'lodash/forEach';

export const translation = {
  "fi-FI": {
    "HI": "MOI",
    "All categories": "Kaikki kategoriat",
    "Selected categories": "Valitut kategoriat",
    "Loading": "Ladataan",
    "View results": "Näytä tulokset",
    "Clear everything": "Tyhjennä kaikki",
    "Search": "Etsi",
    "Read more": "Lue lisää",
    "No results found": "Ei tuloksia",
    "of": "yhteensä",
    "Sort by": "Lajitella",
    "Newest first": "Uusin ensin",
    "Oldest first": "Vanhin ensin",
    "Title A-Z": "Otsikko A-Z",
    "Title Z-A": "Otsikko Z-A",
    "Category filters": "Suodata kategorian mukaan",
    "Sort results": "Järjestä tulokset",
    "Pagination": "Sivutus",
    "Previous page": "Edellinen sivu",
    "Next page": "Seuraava sivu",
    "Page {n}": "Sivu {n}",
    "Filter by {category}": "Suodata {category}",
    "{numberOfEvents} event, {date}": "{numberOfEvents} tapahtuma, {date}",
    "{numberOfEvents} events, {date}": "{numberOfEvents} tapahtumaa, {date}",
    "External link": "Ulkoinen linkki",
    "{amount} items in total": "yhteensä {amount} tulosta",
    "Keywords": "Avainsanat",
    "Event starts at": "Tapahtuma alkaa",
    "Open main menu": "Avaa päävalikko",
    "Close": "Sulje",
    "Menu": "Valikko",
    "Close main menu": "Sulje päävalikko",
    "Open search bar": "Avaa hakukenttä",
    "Close search bar": "Sulje hakukenttä",
    "Go to {month}": "Mene kuukaudelle {month}",
    "No events found for {month} {year}.": "Ei tapahtumia ajankohdalle {month} {year}.",
    "{amount} events found": "{amount} tapahtumaa löydetty",
    "Loading {month}": "{month} ladataan",
  },
  "sv-FI": {
    "HI": "Hej",
    "All categories": "Alla kategorier",
    "Selected categories": "Valda kategorier",
    "Loading": "lastning",
    "View results": "Visa resultat",
    "Clear everything": "Rensa allt",
    "Search": "Sök",
    "Read more": "Läs mer",
    "No results found": "Inga resultat hittades",
    "of": "av",
    "Sort by": "Sort by",
    "Newest first": "Nyaste först",
    "Oldest first": "Äldst först",
    "Title A-Z": "Titel A-Z",
    "Title Z-A": "Titel Z-A",
    "Category filters": "Filtrera enligt kategori",
    "Sort results": "Sortera resultaten",
    "Pagination": "Paginering",
    "Previous page": "Föregående sida",
    "Next page": "Nästa sida",
    "Page {n}": "Sida {n}",
    "Filter by {category}": "Filtrera {category}",
    "{numberOfEvents} event, {date}": "{numberOfEvents} evenemang, {date}",
    "{numberOfEvents} events, {date}": "{numberOfEvents} evenemang, {date}",
    "External link": "Extern link",
    "{amount} items in total": "totalt {amount} resultat",
    "Keywords": "Nyckelord",
    "Event starts at": "Evenemanget börjar",
    "Open main menu": "Öppna huvudmenyn",
    "Close": "Stäng",
    "Menu": "Meny",
    "Close main menu": "Stäng hyvudmenyn",
    "Open search bar": "Öppna sökfältet",
    "Close search bar": "Stäng sökfältet",
    "Go to {month}": "Gå till {month}",
    "No events found for {month} {year}.": "Inga evenemang för {month} {year}.",
    "{amount} events found": "{amount} evenemang hittades",
    "Loading {month}": "{month} laddas",
  },
  "en-US": {
    "HI": "Hello"
  },
};

export function t(val, params = {}) {
    const locale = MMM.language || 'fi-FI';
    const trans = translation[locale];
    let string = trans[val] || val;

    forEach(params, (value, key) => {
        let regex = new RegExp('{' + key + '}');
        string = string.replace(regex, value);
    });

    return string;
}
