<template>
  <transition name="fadeIn" mode="in-out">
    <div v-show="!isLoading && !hideItem" class="mb-10 xxl:mb-12 w-full">
      <template v-if="!!item.image[0]">
        <a :href="item.url" class="block h-full flex flex-col flex-grow">
          <article class="bg-white rounded flex flex-col h-full shadow flex flex-col flex-grow flex-shrink-0 justify-between">
            <figure class="relative aspect-ratio-box aspect-ratio-box--8-5">
              <img
                class="lazyload absolute top-0 left-0 z-0 w-full h-full block object-cover rounded rounded-b-none"
                :src="!!item.image[0] ? item.image[0].imageOptimize.placeholder : '/assets/img/martha_recipe_placeholder.jpg'"
                :data-srcset="!!item.image[0] ? item.image[0].imageOptimize.optimizedImageUrls.join(', ') : '/assets/img/martha_recipe_placeholder.jpg'"
                data-sizes="auto"
                sizes="(min-width: 720px) 25vw, 100vw"
                alt=""
              >
            </figure>

            <div v-if="item.startDate" class="px-12 pb-8 pt-12 extra-small-text">
              <template v-if="!!item.eventCategory.length">
                <span v-for="(cat, key) in item.eventCategory" :key="key">{{ cat.title }}</span>
              </template>
            </div>

            <h4 class="mb-none px-12 heading-4">{{ item.title }}</h4>

            <footer class="px-12 pb-12 pt-16 mt-auto mb-none flex justify-between items-center text-textPrimaryColor group hover:cursor-pointer">
              <span class="font-sans-medium text-xs font-medium tracking-wider">
                <time :datetime="formatDate(item.startDate, 'range', item.endDate)">{{ formatDate(item.startDate, 'range', item.endDate) }}</time>
                KLO. <time :datetime="formatDate(item.startDate, 'time')">{{ formatDate(item.startDate, 'time') }}</time>
              </span>
              <span class="pl-3 group-hover:transform-right text-textPrimaryColor">
                <svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768">
                  <path d="M667.859 357.544l-242.748-242.748 39.104-39.104 303.784 303.784-312.832 312.832-37.973-37.973 243.089-243.089h-660.284v-53.702h667.86z"></path>
                </svg>
              </span>
            </footer>
          </article>
        </a>
      </template>
      <template v-else>
        <a :href="item.url" class="block h-full flex flex-col flex-grow">
          <article class="rounded shadow flex flex-col flex-grow flex-shrink-0 justify-between h-full" :class="[bgColor, textColor]">
            <div v-if="item.startDate" class="px-12 pb-8 pt-12 extra-small-text">
              <!-- <time :datetime="formatDate(item.startDate, 'date')">{{ formatDate(item.startDate, 'date') }}</time> -->
              <template v-if="!!item.eventCategory.length">
                <span v-for="(cat, key) in item.eventCategory" :key="key">{{ cat.title }}</span>
              </template>
            </div>

            <h4 class="mb-none px-12 pb-9 heading-4" :class="textColor">{{ item.title }}</h4>

            <footer class="px-12 pb-12 pt-16 mt-auto mb-none flex justify-between items-center small-text group hover:cursor-pointer" :class="textColor">
              <span class="font-sans-medium text-xs font-medium tracking-wider">
                <time :datetime="formatDate(item.startDate, 'range', item.endDate)">{{ formatDate(item.startDate, 'range', item.endDate) }}</time>
                KLO. <time :datetime="formatDate(item.startDate, 'time')">{{ formatDate(item.startDate, 'time') }}</time>
              </span>
              <span class="pl-3 group-hover:transform-right" :class="textColor">
                <svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768">
                  <path d="M667.859 357.544l-242.748-242.748 39.104-39.104 303.784 303.784-312.832 312.832-37.973-37.973 243.089-243.089h-660.284v-53.702h667.86z"></path>
                </svg>
              </span>
            </footer>
          </article>
        </a>
      </template>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

import 'lazysizes/plugins/attrchange/ls.attrchange';

const arrowRight = '<span class="pl-3 group-hover:transform-right text-textPrimaryColor"><svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768"> <path d="M667.859 357.544l-242.748-242.748 39.104-39.104 303.784 303.784-312.832 312.832-37.973-37.973 243.089-243.089h-660.284v-53.702h667.86z"></path></svg></span>'
const colorCodes = {
  'Maaseutu-saaristo': ['pickledBluewood', 'orient', 'ming', 'aquaForest', 'white'],
  'Maaseutupolitiikka': ['pickledBluewood', 'white'],
  'Saaristopolitiikka': ['pickledBluewood', 'orient', 'ming', 'aquaForest', 'white'],
  'Maaseutuparlamentti': ['parlamenttiBlue', 'parlamenttiBlueLight', 'parlamenttiGreen', 'white']
}

export default {
  name: 'CalendarSearchItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    selectedDate: {
      type: String,
      default: ""
    },
    siteGroup: {
      type: String
    }
  },
  data() {
    return {
      hideItem: false,
      bgColor: 'bg-pickledBluewood'
    }
  },
  created: function() {
    const choosenBgColor = colorCodes[this.siteGroup].random();
    this.bgColor = `bg-${choosenBgColor || 'pickledBluewood'}`
  },
  mounted: function() {
    // CSS object-fit fallback on Edge (and other browsers)
    // Read more, https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3

    // Detect objectFit support
    if ('objectFit' in document.documentElement.style === false) {

      // assign HTMLCollection with parents of images with objectFit to variable
      const container = document.getElementsByClassName('object-fit-fallback')

      // Loop through HTMLCollection
      for (let i = 0; i < container.length; i++) {
        // Asign image source to variable

        if (container[i].querySelector('img') !== null) {
          let imageSource = container[i].querySelector('img').src


          if (
            container[i].querySelector('img').getAttribute('data-srcset') !== null
          ) {
            const firstSrcSet = container[i]
              .querySelector('img')
              .getAttribute('data-srcset')
              .split(', ')[0]
            imageSource = firstSrcSet.split(' ')[0]
          }

          // Hide image
          container[i].querySelector('img').style.display = 'none'

          // Add background-size: cover
          container[i].style.backgroundSize = 'cover'

          // Add background-image: and put image source here
          container[i].style.backgroundImage = 'url(' + imageSource + ')'

          // Add background-position: center center
          container[i].style.backgroundPosition = 'center center'
        }
      }
    }
  },
  watch: {
    "selectedDate": function() {
      this.eventDates();
    },
    isLoading: function(val, oldVal) {
      if(!val && oldVal) {
        const choosenBgColor = colorCodes[this.siteGroup].random();
        this.bgColor = `bg-${choosenBgColor || 'pickledBluewood'}`
      }
    }
  },
  computed: {
    textColor: function() {
      return this.bgColor === ('bg-white') ? 'text-black' : 'text-white'
    }
  },
  methods: {
    formatDate: function(date, format, endDate) {
      const momentDate = moment.unix(date);
      switch (format) {
        case 'time':
          return momentDate.format('HH:mm');
        break;
        case 'day':
          return momentDate.format('dddd');
        break;
        case 'date':
          return momentDate.format('DD.MM.YYYY');
        case 'range':
          if(!!endDate) {
            const momentEndDate = moment.unix(endDate);
            if (momentDate.isSame(momentEndDate, 'day')) {
              return momentDate.format('DD.MM.YYYY');
            } else if (momentDate.isSame(momentEndDate, 'month')) {
              return momentDate.format('DD') + '—' + momentEndDate.format('DD.MM.YYYY');
            } else if (momentDate.isSame(momentEndDate, 'year')) {
              return momentDate.format('DD.MM') + '—' + momentEndDate.format('DD.MM.YYYY');
            }
          } else {
            return momentDate.format('DD.MM.YYYY') + '—';
          }
        break;
      }
    },
    eventDates: function() {
      const self = this;
      const event = this.item;
      const eventStartDate = moment(event.startDate, 'X').startOf('day');
      const eventEndDate = moment(event.endDate, 'X').endOf('day');
      const selectedDate = moment(this.selectedDate, 'X') || "";

      self.hideItem = false;
      if(self.selectedDate.length > 0) {
        if(selectedDate.isSameOrBefore(eventEndDate) && selectedDate.isSameOrAfter(eventStartDate)) {
        } else {
          self.hideItem = true;
        }
      }

      // let eventDateRanges = [];

      // do {
      //   eventDateRanges.push(eventStartDate.format('YYYY-M-D'));
      //   eventStartDate.add(1, 'days');
      // } while (eventStartDate.isSameOrBefore(eventEndDate));

      // return eventDateRanges.join(" ");
    }
  }
};
</script>
