// We import our styles here, webpack will do the rest
import styles from '../scss/app.scss'

import '@babel/polyfill'
import _ from 'lodash'
import Rellax from 'rellax'

// lazy sizes for lazy loading images
import Lazysizes from 'lazysizes'
import 'picturefill'

import Flickity from 'flickity'
import PhotoSwipe from 'photoswipe'

// Our own helpers
import './helpers/objectfitFallback'
import reframe from './helpers/iframe'
import './helpers/iePolyfills'

// Our own components
import './components/primaryNavigation'
import './components/toggle'
import './components/toggleContent'
import './components/preventZoom'
import './components/newsSubscription'
import './components/carousel'
import './components/cookieBanner'
import './components/formStyle'
import './components/focusStyle'
import './components/gallery'
import './components/languagePicker'
import './components/externalLinks'
import './components/cards'
import './components/searchLink'

// Search components
import './search/searchCommon'
import './search/searchPageSearch'
import './search/categorySearch'
import './search/eventCalendar'

// Window onload (after all dom and images are loaded completely)
window.onload = function() {
  // responsive iframes
  reframe(document.querySelectorAll('.wysiwyg iframe')) // Pass a live node list

  // Page has loaded, remove loading class
  document.getElementsByTagName('body')[0].classList.remove('loading')

  // Responsive tables
  // wrap tables in wysiwyg in a div for styling
  const tables = document.querySelectorAll('.wysiwyg table')
  if (tables.length) {
    if (!('length' in tables)) tables = [tables]
    for (let i = 0; i < tables.length; i += 1) {
      const table = tables[i]

      const div = document.createElement('div')
      div.className = 'table-container'

      table.parentNode.insertBefore(div, table)
      table.parentNode.removeChild(table)
      div.appendChild(table)
    }
  }
  if (document.querySelectorAll('.rellax').length) {
    let rellax = new Rellax('.rellax', {
      speed: -2,
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false
    })
  }

  let carousel = document.querySelectorAll('.carousel');
  if (carousel.length > 0) {
    var flkty = new Flickity('.carousel');
    flkty.reloadCells();
  }
}


/*
//GALLERY
var modal = document.querySelector('.modal')
var modalImage = document.querySelector('.modal-img')
var modalClose = document.querySelector('.modal-close')
var modalImagePrevious = document.querySelector('.btn-previous')
var modalImageNext = document.querySelector('.btn-next')
var galleryItems = document.querySelectorAll('.gallery-item')
var galleryImages = document.querySelectorAll('.gallery-img')

galleryItems.forEach(item => item.addEventListener('click', imgClick))

function imgClick(e) {
  modal.classList.remove("hidden")
  modalImage.src = e.target.dataset.src;
}

modalClose.addEventListener("click", function(){
  modal.classList.add("hidden")
})

modalImagePrevious.addEventListener("click", function(){

  for (let k = 0; k < galleryImages.length; k++) {
    console.log(galleryImages[k])
    if (galleryImages[k].src === modalImage.src) {
      modalImage.src = galleryImages[k-1].src;
      break;
    }
  }
})

modalImageNext.addEventListener("click", function(){

  for (let k = 0; k < galleryImages.length; k++) {
    console.log(galleryImages[k])
    if (galleryImages[k].src === modalImage.src) {
      modalImage.src = galleryImages[k+1].src;
      break;
    }
  }
})*/

/*var collageGallery = function() {
  var galleryHtml =
    '<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true"><div class="pswp__bg"></div><div class="pswp__scroll-wrap"><div class="pswp__container"><div class="pswp__item"></div><div class="pswp__item"></div><div class="pswp__item"></div></div><div class="pswp__ui pswp__ui--hidden"><div class="pswp__top-bar"><div class="pswp__counter"></div><button class="pswp__button pswp__button--close" title="Close (Esc)"></button><div class="pswp__preloader"><div class="pswp__preloader__icn"><div class="pswp__preloader__cut"><div class="pswp__preloader__donut"></div></div></div></div></div><div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"><div class="pswp__share-tooltip"></div></div><button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button><button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button><div class="pswp__caption"><div class="pswp__caption__center"></div></div></div></div></div>';
  var $galleryImage = $('.apartment__image');
  var $galleryTrigger = $('.show-gallery');
  var openGallery = function($gallery, slideIndex) {
    var galleryElem = $('body').find('.pswp')[0];
    var items = JSON.parse($gallery.attr('data-slides'));

    var options = {
      index: slideIndex,
      getThumbBoundsFn: function(index) {
        var $collage = $galleryImage;
        var pageYScroll =
          window.pageYOffset || document.documentElement.scrollTop;
        var rect = $collage[0].getBoundingClientRect();

        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
      },
      showHideOpacity: true,
      showAnimationDuration: 333,
      hideAnimationDuration: 333,
      bgOpacity: 0.9,
      spacing: 0.12,
      allowPanToNext: true,
      maxSpreadZoom: 2,
      loop: true,
      pinchToClose: true,
      closeOnScroll: false,
      closeOnVerticalDrag: true,
      mouseUsed: false,
      escKey: true,
      arrowKeys: true,
      history: false,
      errorMsg:
        '<div class="pswp__error-msg"><a href="%url%" target="_blank">The image</a> could not be loaded.</div>',
      focus: true,
      modal: true,
    };
    var gallery = new PhotoSwipe(
      galleryElem,
      PhotoSwipeUI_Default,
      items,
      options
    );

    gallery.init();

    gallery.listen('gettingData', function(index, item) {
      if (item.w < 1 || item.h < 1) {
        var img = new Image();
        img.onload = function() {
          item.w = this.width;
          item.h = this.height;
          gallery.updateSize(true);
        };
        img.src = item.src;
      }
    });
  };

  if ($galleryTrigger.length) {
    $('body').append(galleryHtml);

    $galleryImage.on('click', function(e) {
      e.preventDefault();

      var $chosenGallery = $(this).parents('.apartment__images');
      // var $slides = $chosenGallery.find('.apartment__image');
      var slideIndex = parseInt($(this).attr('data-index'));

      openGallery($chosenGallery, slideIndex);

      return false;
    });

    $galleryTrigger.on('click', function(e) {
      e.preventDefault();

      var $chosenGallery = $('.apartment__images');
      var slideIndex = 0;

      openGallery($chosenGallery, slideIndex);

      return false;
    });
  }
};

$(document).ready(function() {
  // gallery();
  collageGallery();
});*/
