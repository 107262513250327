<template>
  <section class="-mt-44 mb-20-5 relative z-20 px-10 sm:px-20">
    <div class="content-wrapper--medium mx-auto">
      <div class="bg-white flex flex-wrap shadow rounded">
        <div class="w-full lg:w-4/12">
          <div class="inline-block relative w-full h-full">
            <label class="visually-hidden" for="category-filters">{{'Category filters'|t}}</label>
            <select
              id="category-filters"
              class="block appearance-none w-full bg-white border border-transparent py-17 pl-16 pr-24 rounded default-text text-ebonyClay"
              @change="categoryFilters.length > 0 && categorySelector(_.find(categoryFilters[0].categories, {title: $event.target.value}), categoryFilters[0].groupTitle)"
              :value="categoryFilters.length > 0 && _.find(selectedCategories, {groupTitle: categoryFilters[0].groupTitle}) ? _.get(_.find(selectedCategories, {groupTitle: categoryFilters[0].groupTitle}), 'title') : 'All categories'|t"
            >
              <option>{{"All categories"|t}}</option>
              <template v-if="categoryFilters.length > 0">
              <option  v-for="(cat, key) in categoryFilters[0].categories" :key="key">{{ cat.title }}</option>
              </template>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-13">
              <span
                class="flex justify-center items-center w-14 h-14 text-grayDarker rounded-full border border-lightGray"
              >
                <svg class="fill-current block" width="7px" height="7px" viewBox="0 0 768 768">
                  <path
                    d="M384 460.8l307.2-307.2 76.8 76.8-384 384-384-384 76.8-76.8 307.2 307.2z"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-4/12 border-l border-r border-grey">
          <div class="inline-block relative w-full">
            <label class="visually-hidden" for="sort">{{'Sort results'|t}}</label>
            <select
              id="sort"
              :sorting.sync="sorting"
              @change="$emit('update:sorting', $event.target.value)"
              class="block appearance-none w-full bg-white border border-transparent py-17 pl-16 pr-24 rounded default-text text-ebonyClay"
            >
              <option
                v-for="(option, value) in sortingOptions"
                :key="value"
                :value="option.value"
                :selected="option.value === sorting"
              >{{ option.title|t }}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-13">
              <span
                class="flex justify-center items-center w-14 h-14 text-grayDarker rounded-full border border-lightGray"
              >
                <svg class="fill-current block" width="7px" height="7px" viewBox="0 0 768 768">
                  <path
                    d="M384 460.8l307.2-307.2 76.8 76.8-384 384-384-384 76.8-76.8 307.2 307.2z"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-4/12" :class="{'bg-mystic' : isInputFocused}">
          <div
            class="py-16 pl-16 pr-13 flex justify-between items-center default-text text-ebonyClay cursor-text"
            @click="focusInput()"
          >
            <label class="visually-hidden" for="search">{{ 'Search'|t }}</label>
            <input
              v-bind:value="query"
              @input="$emit('update:query', $event.target.value)"
              @focus="isInputFocused = true"
              @blur="isInputFocused = false"
              class="bg-transparent font-sans font-normal text-base leading-none appearance-none border-none w-full text-ebonyClay h-14"
              :class="{'bg-grey-light opacity-50': isCategoryFilterOpen}"
              type="text"
              :placeholder="'Search'|t"
              ref="input"
              id="search"
            />
            <button
              class="flex flex-shrink-0 justify-center items-center w-14 h-14 text-grayDarker rounded-full border border-lightGray"
              name="'Search button'|t"
              @click.stop="scrollToResults()"
            >
              <span class="visually-hidden">{{ 'Search'|t }}</span>
              <svg class="fill-current block" width="12px" height="12px" viewBox="0 0 768 768">
                <path
                  d="M474.774 524.851c-111.298 86.326-273.173 77.372-376.684-26.139-112.104-112.104-113.299-292.665-2.671-403.294s291.19-109.433 403.294 2.671c103.511 103.511 112.465 265.388 26.139 376.684l229.925 229.925-50.077 50.077-229.925-229.925zM148.167 448.635c84.079 84.079 219.499 84.975 302.471 2.003s82.075-218.392-2.003-302.47c-84.079-84.079-219.499-84.975-302.47-2.003s-82.075 218.392 2.003 302.471z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-10 flex flex-col lg:flex-row">
        <div
          v-for="(group, key) in _.slice(categoryFilters, 1)"
          class="mt-4 md:mt-0 flex-no-grow"
          :class="{'lg:ml-30': categoryFilters.length > 2 && key === _.slice(categoryFilters, 1).length - 1}"
          :key="key"
          data-toggle-container
        >
          <button
            class="cursor-pointer uppercase extra-small-text-bold"
            @click="openFilters === key ? openFilters = null : openFilters = key"
            ref="togglefilters"
            :tabindex="(openFilters !== null && key !== openFilters) ? '-1' : false"
            :aria-label="'Filter by {category}'|t({category: group.groupTitle})"
            :aria-expanded="openFilters === key ? 'true' : 'false'"
          >
            <div
              class="inline-block"
              :class="{'opacity-60': openFilters !== key && openFilters !== null}"
            >{{ group.groupTitle }}</div>
            <div
              class="inline-block opacity-60 mx-5"
              v-if="filtersAmount(group) > 0"
            >{{ filtersAmount(group) }} valittu</div>
            <svg
              :class="{'opacity-60': openFilters !== key && openFilters !== null, 'ml-5': filtersAmount(group) === 0}"
              class="inline-block"
              width="7px"
              height="4px"
              viewBox="0 0 7 4"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-310.000000, -503.000000)" fill="#202730">
                  <polygon
                    transform="translate(313.500000, 505.000000) rotate(-180.000000) translate(-313.500000, -505.000000) "
                    points="313.5 503 317 507 310 507"
                  />
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div class="mt-10 bg-white shadow rounded">
        <div
          class="mt-10 bg-white p-10 flex flex-col lg:flex-row lg:items-center justify-between"
          :class="{'hidden': selectedCategories.length === 0}"
        >
          <div class="lg:flex lg:flex-row">
            <div class="lg:mr-10">
              {{"Selected categories"|t}}
            </div>
            <div class="mt-10 lg:mt-0">
              <span
                v-for="(cat, key) in selectedCategories"
                :key="key"
              >
                <button
                  class="leading-none button-round mb-3 mr-6 inline-flex flex-no-shrink px-6"
                  @click="categorySelector(cat, cat.groupTitle);"
                  :id="cat.id"
                  :class="_.find(selectedCategories, {id: cat.id}) ? 'button-dark-gray' : allCategories.includes(cat.title) ? 'button-light-gray' : 'opacity-30 pointer-events-none'"
                >
                  {{cat.title}}
                  <div class="inlin-block ml-1-5" style="transform: rotate(45deg)">
                    <svg
                      class="stroke-current block pointer-events-none"
                      width="14px"
                      height="14px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke-width="3"
                      stroke-linecap="square"
                      stroke-linejoin="arcs"
                      preserveAspectRatio="none"
                    >
                      <line x1="12" y1="5" x2="12" y2="19" />
                      <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                  </div>
                </button>
              </span>
            </div>
          </div>
          <button
            v-if="openFilters !== null && selectedCategories.length !== 0"
            @click="scrollToResults"
            class="button button-small button-green mt-10 lg:mt-0 w-full lg:w-auto"
          >{{isLoading ? $options.filters.t("Loading") : $options.filters.t("View results") + '(' + totalItems + ')'}}</button>
          <button
            @click="clearSelectedCategories"
            v-else
            class="button button-small button-outline mt-10 lg:mt-0 w-full lg:w-auto"
          >{{"Clear everything"|t}}</button>
        </div>
        <div
          class="mx-10 bg-white pt-10 pb-10"
          :class="{'hidden': openFilters === null, 'border-t border-lightGray': selectedCategories.length > 0}"
        >
          <div
            v-for="(group, key) in _.slice(categoryFilters, 1)"
            :key="key"
            :class="{'hidden': key !== openFilters}"
          >
            <button
              v-for="(cat, key) in group.categories"
              :key="key"
              class="leading-none button-round mb-3 mr-6 inline-flex flex-no-shrink px-6"
              @click="categorySelector(cat, group.groupTitle);"
              :id="cat.id"
              :class="_.find(selectedCategories, {id: cat.id}) ? 'button-dark-gray' : allCategories.includes(cat.title) ? 'button-light-gray' : 'opacity-30 pointer-events-none'"
              :aria-pressed="_.find(selectedCategories, {id: cat.id}) ? 'true' : 'false'"
              :disabled="!allCategories.includes(cat.title)"
            >{{cat.title}}</button>
            <button
              class="leading-none button-round mb-3 mr-6 inline-flex flex-no-shrink px-6"
              @click="toggleOpenFilters(key)"
            >{{ 'Close'|t }}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash'
import {translation} from '../js/search/translation'

export default {
  name: 'CategoryFilter',
  props: {
    query: {
      type: String,
      default: '',
    },
    sectionType: {
      type: Array,
      default: [],
    },
    categoryFilters: {
      type: Array,
      default: [],
    },
    selectedCategory: {
      type: Object,
      default: () => ({
        title: {
          type: String,
        },
        slug: {
          type: String,
        },
        id: {
          type: String,
        },
        groupTitle: {
          type: Object,
        },
      }),
    },
    allCategories: {
      type: Array,
      default: () => [],
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
    sorting: {},
    sortingOptions: {
      type: Array,
      default: () => [
        {
          title: 'Newest first',
          value: 'postdate desc',
        },
        {
          title: 'Oldest first',
          value: 'postdate asc',
        },
        {
          title: 'Title A-Z',
          value: 'title asc',
        },
        {
          title: 'Title Z-A',
          value: 'title desc',
        },
      ],
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isCategoryFilterOpen: false,
      openFilters: null,
      isInputFocused: false
    }
  },
  created: function() {
    const self = this
    const catBlocks = _.flatMapDeep(this.categoryFilters)
    let newCats = []

    _.each(catBlocks, catBlock => {
      if (!!catBlock && this.selectedCategories.includes(catBlock.id)) {
        this.selectedCategories.push(catBlock)
      } else {
        const cats = _.filter(catBlock.categories, c => {
          return this.selectedCategories.includes(c.id)
        })
        if (!!cats) {
          newCats = _.union(newCats, cats)
        }
      }
    })
    this.$emit('update:selected-categories', newCats)
  },
  mounted: function() {
    document.addEventListener('click', this.closeCategoryFilter, false)
    this.toggleCat()
  },
  updated: function() {},
  destroyed: function() {
    document.removeEventListener('click', this.closeCategoryFilter)
  },
  computed: {
    _() {
      return _
    },
  },
  filters: {
    t(val, params = {}) {
      const locale = document.getElementById('searchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      let string = trans[val] || val;

      _.each(params, (value, key) => {
        let regex = new RegExp('{' + key + '}');
        string = string.replace(regex, value);
      });

      return string;
    },
  },
  methods: {
    filtersAmount: function(group) {
      return _.filter(
        this.selectedCategories,
        o => _.get(o, 'groupTitle') === group.groupTitle,
      ).length
    },
    closeCategoryFilter: function(event) {
      const isInsideContainer = this.parents(
        event.target,
        document.getElementById('filterContainer'),
      )
      if (
        !(
          event.target.classList.contains('preventClosingFilterContainer') ||
          isInsideContainer
        )
      ) {
        this.isCategoryFilterOpen = false
      }
    },
    parents: function(el, parentSelector) {
      const grandParentSelector = !!parentSelector
        ? parentSelector.parentElement
        : null
      if (!grandParentSelector) {
        return false
      }

      let hasParents = false
      let p = el.parentElement

      if (el === parentSelector) {
        hasParents = true
      } else {
        while (!!p && p !== grandParentSelector) {
          var o = p
          if (o === parentSelector) {
            hasParents = true // Push that parentSelector you wanted to stop at
          }
          p = o.parentElement
        }
      }

      return hasParents
    },
    toggleCategoryFilter: function() {
      this.isCategoryFilterOpen = !this.isCategoryFilterOpen
    },
    toggleOpenFilters: function(key) {
      this.openFilters === key ? this.openFilters = null : this.openFilters = key
      this.$refs.togglefilters[key].focus()
    },
    categorySelector: function(cat, groupCat) {
      if (cat === 'startDate') {
        this.$emit('update:start-date', groupCat)
      } else {
        let newCats = []
        this.selectedCategories.forEach(element => {
          newCats.push(element)
        })
        if (!_.get(cat, 'id')) {
          newCats = _.reject(newCats, {
            groupTitle: this.categoryFilters[0].groupTitle,
          })
        } else {
          if (this.categoryFilters[0].groupTitle === groupCat) {
            newCats = _.reject(newCats, {groupTitle: groupCat})
          }
          if (_.find(this.selectedCategories, {id: cat.id})) {
            _.remove(newCats, {id: cat.id})
          } else {
            let catObj = {}
            if (!cat) {
            } else {
              catObj.title = _.get(cat, 'title')
              catObj.slug = _.get(cat, 'slug')
              catObj.id = _.get(cat, 'id')
              catObj.groupTitle = !!groupCat ? groupCat : undefined
              newCats.push(catObj)
            }
          }
        }
        this.$emit('update:selected-categories', newCats)
      }
    },
    clearSelectedCategories: function() {
      this.$emit('update:selected-categories', [])
    },
    scrollToResults: function() {
      this.openFilters = null
      const element = document.getElementById('search-results')
      const offset = 200
      const bodyRect = document.body.getBoundingClientRect().top
      const elementRect = element.getBoundingClientRect().top
      const elementPosition = elementRect - bodyRect
      const offsetPosition = elementPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    },
    toggleCat: function() {
      // assign HTMLCollection with parents of images with objectFit to variable
      const toggleContainer = document.querySelectorAll(
        '[data-toggle-container]',
      )

      // Loop through HTMLCollection
      for (let i = 0; i < toggleContainer.length; i++) {
        const toggle = toggleContainer[i].querySelector('[data-toggle]')

        if (toggle !== null) {
          toggle.onclick = function(e) {
            e.preventDefault()
            const toggleItems = toggleContainer[i].querySelectorAll(
              '[data-toggle-class]',
            )

            for (let j = 0; j < toggleItems.length; j++) {
              const toggleClass = toggleItems[j].getAttribute(
                'data-toggle-class',
              )

              if (toggleItems[j].classList) {
                toggleItems[j].classList.toggle(toggleClass)
              } else {
                var classes = toggleItems[j].className.split(' ')
                var existingIndex = classes.indexOf(toggleClass)

                if (existingIndex >= 0) classes.splice(existingIndex, 1)
                else classes.push(toggleClass)

                toggleItems[j].className = classes.join(' ')
              }
            }

            // if toggle text is found, toggle the text
            if (toggle.querySelector('[data-toggle-text]') !== null) {
              const toggleText = toggle.querySelector('[data-toggle-text]')
              const originalText = toggleText.innerHTML
              const newText = toggleText.getAttribute('data-toggle-text')

              toggleText.innerHTML = newText
              toggleText.setAttribute('data-toggle-text', originalText)
            }
          }
        }
      }
    },
    focusInput: function() {
      this.$refs.input.focus();
    },
  },
}
</script>
