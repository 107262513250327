<template>
  <div class="content-wrapper--medium mx-auto flex flex-wrap justify-between" :class="sectionType[0] === 'events' || sectionType[0] === 'searchLanding' ? 'mb-0' : 'mb-12'">
    <div class="w-1/2" :class="sectionType[0] === 'events' || sectionType[0] === 'searchLanding' ? 'mb-0' : 'mb-3 md:mb-0'">
      <div v-if="itemsAmount > 0" class="extra-small-text-bold text-blue-dark">
        <span>{{itemOffset + 1}} – {{itemsAmount}}</span> <span role="status" class="text-scorpion">({{ '{amount} items in total'|t({amount: totalItems}) }})</span>
      </div>
      <div v-else class="extra-small-text-bold">
        <span>0</span> <span role="status" class="text-scorpion">({{ '{amount} items in total'|t({amount: 0}) }})</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {translation} from '../js/search/translation'
import {t} from '../js/search/translation'
import SearchSorting from './SearchSorting'

export default {
  name: 'CategorySearchMeta',
  props: {
    totalItems: {
      type: Number,
      default: 0
    },
    itemOffset: {
      type: Number,
      default: 0
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    'sectionType': {
      type: Array,
      default: []
    },
    sortingOptions: {
      type: Array,
      default: () => [
        {
          title: 'Newest first',
          value: 'postdate desc'
        },
        {
          title: 'Oldest first',
          value: 'postdate asc'
        },
        {
          title: 'Title A-Z',
          value: 'title asc'
        },
        {
          title: 'Title Z-A',
          value: 'title desc'
        }
      ]
    }
  },
  data() {
    return {
      searchSorting: ''
    }
  },
  computed: {
    itemsAmount: function() {
     const itemsLeft = this.totalItems - this.itemOffset > this.elementsPerPage ? this.itemOffset + this.elementsPerPage : this.totalItems;

     return itemsLeft;
    }
  },
  filters: {
    t(val, params = {}) {
      // const locale = (document.getElementById('searchApp') || document.getElementById('searchPage')).dataset.locale || 'fi-FI';
      // const trans = translation[locale];
      // return trans[val] || val;
      return t(val, params);
    },
  },
  watch: {
    searchSorting: function(val) {
      this.$emit('update:searchSorting', val)
    }
  },
  components: {
    SearchSorting
  },
};
</script>
