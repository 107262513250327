<template>
    <div v-if="windowWidth > 1080" v-html="element.outerHTML"></div>
    <div v-else @keydown.esc="mobileMenuOpen = false">
        <button
            @click="toggleMobileMenu"
            :class="{'is-open': mobileMenuOpen}"
            :aria-label="'Open main menu'|t"
            :aria-expanded="mobileMenuOpen ? 'true' : 'false'"
            aria-controls="mobile-menu"
            ref="toggleMobileMenuButton"
            class="hamburger xxl:ml-0 flex items-center extra-small-text-bold uppercase relative pr-41 md:pr-53 ml-auto self-stretch z-20"
        >
            <span class="absolute p-4">
                <span class="flex">
                    <span>
                        <span class="hamburger__line"></span>
                        <span class="hamburger__line"></span>
                        <span class="hamburger__line"></span>
                    </span>
                    <span class="extra-small-text-bold pl-6" v-if="mobileMenuOpen">{{ 'Close'|t }}</span>
                    <span class="extra-small-text-bold pl-6" v-else>{{ 'Menu'|t }}</span>
                </span>
            </span>
        </button>
        <div
            id="mobile-menu"
            class="dropdown-container"
            :class="{'is-open': mobileMenuOpen}"
        >
            <div v-html="element.outerHTML"></div>
            <div class="mb-16">
                <button class="visually-hidden border-transparent button button-small button-green" @click="toggleMobileMenu">{{ 'Close main menu'|t }}</button>
            </div>
        </div>
    </div>
</template>

<script>

import {t} from '../js/search/translation';
import { store } from '../js/store/header';

export default {
    props: {
        element: {
            type: HTMLElement,
            required: true
        }
    },

    data() {
        return store;
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    filters: {
        t(string, params = {}) {
            return t(string, params);
        }
    },

    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },

        toggleMobileMenu() {
            this.mobileMenuOpen = !this.mobileMenuOpen;
        }
    },

    watch: {
        mobileMenuOpen() {
            if (this.mobileMenuOpen) {
                this.searchOpen = false;
            }

            this.$nextTick(() => {
                if (!this.searchOpen) {
                    this.$refs.toggleMobileMenuButton.focus();
                }
            })
        }
    }
}

</script>
