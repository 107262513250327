<template>
  <div class="filter-wrap">
    <div class="filter">
      {{selectedCategory.title || translations["All sites"]}}
      <span class="filter-btn">
      </span>
    </div>
    <select class="select-filter" @change="onChange($event)">
      <option v-for="(filter, key) in categoryFilters" :key="key" :value="filter.id">
        {{_.startCase(filter.slug)}}
      </option>
    </select>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CategoryFilter',
  props: {
    'categoryFilters': {
      type: Array,
      default: []
    },
    'selectedCategory': {
      type: Object,
      default: () => ({
        title: {
          type: String
        },
        slug: {
          type: String
        },
        id: {
          type: String
        }
      })
    },
    'translations': {
      type: Object
    }
  },
  created: function() {
    const self = this;
    const catBlocks = _.flatMapDeep(this.categoryFilters);
    const selectedId = this.selectedCategory.id;

    _.each(catBlocks, (catBlock)=> {
      if(!!catBlock.groupCategory && catBlock.groupCategory.id === selectedId) {
        this.selectedCategory = catBlock.groupCategory;
      } else {
        const cat = _.find(catBlock.categories, (c)=>{
            return c.id === selectedId
        });
        if(!!cat) {
          this.selectedCategory.title = cat.title;
          this.selectedCategory.slug = cat.slug;
          this.selectedCategory.id = cat.id;
          this.selectedCategory.parentCategory = catBlock.groupCategory;
        }
      }
    })

  },
  mounted: function() {
  },
  updated: function() {
  },
  destroyed: function() {
  },
  data() {
    return {
    };
  },
  computed: {
    _() {
      return _;
    }
  },
  filters: {
  },
  methods: {
    onChange(event) {
      const cat = _.find(this.categoryFilters, (c)=>{
          return c.id === event.target.value
      });
      
      this.$emit('update:selected-category', cat);
    }
  },
}
</script>

<style lang="scss" scoped>
  .filter-wrap {
    @apply relative;
    @apply cursor-pointer;
    &:focus-within {
      outline: 2px solid #529DFF !important;
    }
  }
  .filter {
    @apply flex;
    @apply items-center;
    @apply justify-between;
    @apply bg-white;
    @apply m-0 #{!important};
    @apply px-17;
    @apply py-16;
    @apply rounded;
    @apply text-textPrimaryColor;
    @apply font-sans;
    @apply text-base #{!important};
    @apply text-center;
    @apply shadow;
  }
  .filter-btn {
    @apply relative;
    @apply w-14;
    @apply h-14;
    @apply m-0 #{!important};
    @apply border;
    @apply border-lightGray;
    @apply rounded-full;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    transform: rotate(135deg);
    &:after {
      content: "";
      @apply absolute;
      @apply border-grayDarker;
      @apply border-t;
      @apply border-r;
      @apply w-2-5;
      @apply h-2-5;
      left: 10px;
      top: 11px;
    }
  }
  .select-filter {
    @apply w-full;
    @apply h-full;
    @apply absolute;
    @apply z-10;
    @apply top-0;
    @apply opacity-0;
  }
</style>