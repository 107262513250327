const elements = document.querySelectorAll("a, button, input[type='button']");
let mouseDown = false;

for (let i = 0; i < elements.length; i++) {
  elements[i].addEventListener('mousedown', () => {
    mouseDown = true;
  });
  
  elements[i].addEventListener('mouseup', () => {
    mouseDown = false;
  });
  
  elements[i].addEventListener('focus', (event) => {
    if (mouseDown) {
      event.target.blur();
    }
  });
}