<template>
  <div class="mb-0 px-5 sm:px-10 w-1/2 flex flex-wrap items-center justify-center md:justify-end">
    <div class="extra-small-text-bold uppercase text-ebonyClay pr-2">{{"Sort by"|t}}:</div>
    <div class="flex items-center">
      <select
        :sorting.sync="sorting"
        @change="$emit('update:sorting', $event.target.value)"
        class="block appearance-none w-full bg-white border border-transparent px-4 py-2 pr-8 extra-small-text-bold uppercase opacity-60"
      >
        <option v-for="(option, value) in options" :key="value" :value="option.value" :selected="option.value === sorting">{{ option.title }}</option>
      </select>
      <div class="pointer-events-none">
        <span class="text-ebonyClay">
          <svg width="7px" height="7px" viewBox="0 0 768 768" class="fill-current block">
            <path d="M384 460.8l307.2-307.2 76.8 76.8-384 384-384-384 76.8-76.8 307.2 307.2z"></path>
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {translation} from '../js/search/translation'

export default {
  name: 'SearchSorting',
  props: [
    'sorting',
    'options'
  ],
  filters: {
    t(val) {
      const locale = document.getElementById('searchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  options: {
    type: Object,
    default: [
      {
          title: 'Newest first',
          value: 'postdate desc'
        },
        {
          title: 'Oldest first',
          value: 'postdate asc'
        },
        {
          title: 'Title A-Z',
          value: 'title asc'
        },
        {
          title: 'Title Z-A',
          value: 'title desc'
        }
    ],
    required: true,
  }
};
</script>
