/*
  -------------
  takes 2 args:
  => target: targeted <element>
  => cname: optional custom classname
  -------------
  defines the height/width ratio of the targeted <element>
*/
export default function reframe(target, cName) {
  let frames =
    typeof target === 'string' ? document.querySelectorAll(target) : target
  const c = cName || 'js-reframe'
  if (!('length' in frames)) frames = [frames]
  for (let i = 0; i < frames.length; i += 1) {
    const frame = frames[i]
    // makes sure reframe is not run more than 1x ✔️
    const hasClass = frame.className.split(' ').indexOf(c) !== -1
    if (hasClass) continue

    // get height width attributes
    const hAttr = frame.getAttribute('height')
    const wAttr = frame.getAttribute('width')
    // // if has percentage width assume the iframe is responsive
    // This did not work when iframe had no width or height attributes
    // if (wAttr.indexOf('%') > -1 || frame.style.width.indexOf('%') > -1) continue
    // const h = hAttr || frame.offsetHeight
    // const w = wAttr || frame.offsetWidth

    // try to use `offsetSize` if the size attribute is not defined or of it uses a percentage
    const shouldUseSzAttr = a =>
      !frame.getAttribute(a) || frame.getAttribute(a).toString('%')
    const h = shouldUseSzAttr('height')
      ? frame.offsetHeight
      : parseInt(frame.getAttribute('height'))
    const w = shouldUseSzAttr('width')
      ? frame.offsetWidth
      : parseInt(frame.getAttribute('width'))

    // general targeted <element> sizes
    const padding = (h / w) * 100

    // created element <wrapper> of general reframed item
    // => set necessary styles of created element <wrapper>
    const div = document.createElement('div')
    div.className = c
    const divStyles = div.style
    divStyles.position = 'relative'
    divStyles.width = '100%'
    divStyles.paddingTop = `${padding}%`

    // set necessary styles of targeted <element>
    const frameStyle = frame.style
    frameStyle.position = 'absolute'
    frameStyle.width = '100%'
    frameStyle.height = '100%'
    frameStyle.left = '0'
    frameStyle.top = '0'

    // reframe targeted <element>
    frame.parentNode.insertBefore(div, frame)
    frame.parentNode.removeChild(frame)
    div.appendChild(frame)
  }
}
