import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import moment from "moment";
import {configureApi, apiToken, apiUrl, getQueryParam, executeQuery} from './searchCommon';

import CalendarSearchList from '../../vue/CalendarSearchList'
import Calendar from '../../vue/Calendar'
import CalendarFilter from '../../vue/CalendarFilter'
import EventList from '../../vue/EventList'

const locale = !!document.getElementById('eventCalendar') && document.getElementById('eventCalendar').dataset.locale || 'fi';
const translations = {
  "en": {
    "All sites": "All sites",
    "No results found": "No results found"
  },
  "fi": {
    "All sites": "Kaikki tahot",
    "No results found": "Ei tuloksia"
  },
  "sv": {
    "All sites": "alla webbplatser",
    "No results found": "Inga resultat funna"
  }
}

// Site search application
export const getcategorySearchLandingData = {
  sectionType: '',
  siteGroup: '',
  searchApi: axios.create(configureApi(apiUrl, apiToken)),
  searchQuery: '',
  searchCategory: {},
  currentDate: new Date(),
  startDate: moment().startOf('month').format('X'),
  endDate: moment().endOf('month').format('X'),
  selectedDate: '',
  hasEventOnSelectedDay: true,
  sites: [],
  searchResults: [],
  sortedDates: {},
  searchSorting: 'startDate asc',
  meta: {
    totalPages: 1,
    currentPage: 1,
    elementsPerPage: 6,
    totalCount: 0,
    offset: 0
  },
  isLoading: true,
  isReady: false,
  categoryDatesArray: {},
  calendarHeight: 0,
  locale: locale,
  translations: translations[locale]
}

var filterChangeTimer;

var eventCalendar = function() {

const siteGroup = document.getElementById('eventCalendar').dataset.siteGroup;

// What to search for
const searchSections = JSON.parse(document.getElementById('eventCalendar').dataset.section);
const searchEntries = JSON.parse(document.getElementById('eventCalendar').dataset.entries);
const searchTransform = JSON.parse(document.getElementById('eventCalendar').dataset.transform);
const sites = JSON.parse(document.getElementById('eventCalendar').dataset.sites);
const searchUri = document.getElementById('eventCalendar').dataset.uri;
const section = document.getElementById('eventCalendar').dataset.section;

let searchQueries = ''
_.each(searchEntries, (entryType) => {
  searchQueries = searchQueries +
      `
      ... on ${entryType} {
        id
        title
        url
        image {
          ...on GeneralVolume {
            url
            title
            imageOptimize {
              ...on OptimizedImagesData {
                optimizedImageUrls
                placeholder
              }
            }
          }
        }
        postDate
        eventCategory {
          title
        }
        startDate
        endDate
        ${searchTransform.join('\n')}
      }`
});

// The query to search for entries in Craft
const searchQuery =
  `
  query searchQuery($sections: [SectionsEnum], $needle: String, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $siteHandle: String, $uri: String )
    {
      entriesConnection( limit: $limit, offset: $offset, orderBy: $orderBy, search: $needle, section: $sections, startDate:$startDate, endDate:$endDate, site:$siteHandle, uri:$uri) {
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          currentPage
          totalPages
          first
          last
        }
        entries{
          ${searchQueries}
        }
      }
    }
  `;

  new Vue({
    el: document.getElementById('eventCalendar'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData,
    components: {
      // CalendarSearchList,
      // CalendarFilter,
      // Calendar
      EventList
    },
    render: function(createElement) {
      return createElement(EventList)
    },
    created: function() {
      // this.selected.location = !!$eventLanding.data('location') && $eventLanding.data('location');
      this.sites = sites;

      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if(!!categoryParam) {
        // this.searchCategory.slug = categoryParam;
      }
      if(!!categoryIdParam) {
        this.searchCategory.id = categoryIdParam;
      }
      if(!!pageParam) {
        this.meta.currentPage = parseInt(pageParam);
      }
      this.sectionType = searchSections;
      this.siteGroup = siteGroup;
    },
    mounted: function() {
      // document.getElementsByClassName('vfc-arrow-left')[0].setAttribute('tabindex', '0');
      // document.getElementsByClassName('vfc-arrow-left')[0].setAttribute('aria-label', 'See previous month');
      // document.getElementsByClassName('vfc-arrow-right')[0].setAttribute('tabindex', '0');
      // document.getElementsByClassName('vfc-arrow-right')[0].setAttribute('aria-label', 'See next month');
      this.performSearch();
      this.focusStyle();
    },
    updated: function() {
      this.focusStyle();
    },
    destroyed: function destroyed() {
    },
    watch: {
      'meta.currentPage': function(val) {
        this.scrollup();
        this.meta.offset = this.meta.elementsPerPage * (val - 1);
        this.performSearch();
      },
      searchQuery: function(val, oldVal) {
        if(!!oldVal && val !== oldVal) {
          this.meta = _.get(getcategorySearchLandingData, 'meta');
        }
        this.performSearch();
      },
      searchCategory: {
        handler: function(val, oldVal) {
          if(!!oldVal && val.id !== oldVal.id) {
            this.meta = _.get(getcategorySearchLandingData, 'meta');
          }
          this.performSearch();
        },
        deep: true
      },
      searchSorting: function() {
        this.scrollup();
        this.performSearch();
      },
      startDate: function() {
        this.scrollup();
        this.performSearch();
      },
    },
    methods: {
      focusStyle() {
        // Focus style
        const elements = this.$el.querySelectorAll("a, button, input[type='button'], select");
        let mouseDown = false;

        for (let i = 0; i < elements.length; i++) {
          elements[i].addEventListener('mousedown', () => {
            mouseDown = true;
          });

          elements[i].addEventListener('mouseup', () => {
            mouseDown = false;
          });

          elements[i].addEventListener('focus', (event) => {
            if (mouseDown) {
              event.target.classList.add('mouseDown');
            }
          });
          elements[i].addEventListener('blur', (event) => {
            event.target.classList.remove('mouseDown');
          });
        }
      },
      performSearch() {
        var self = this;

        self.isLoading = true;
        self.setHistory();
        let searchTitle = !!self.searchQuery ? `title:*${self.searchQuery}*` : '';
        const searchCategoryString = _.get(self.searchCategory, 'title');
        const searchCategory = !!searchCategoryString && `eventCategory:"${searchCategoryString}" OR eventCategory::"${searchCategoryString}"`;
        const searchTag = !!self.searchTag && `tags:${self.searchQuery} OR tags::${self.searchQuery}`;
        const searchString = _.compact([searchCategory, searchTag, ]).join(' OR ');

        // Set the variables we will pass in to our query
        const variables = {
            sections: searchSections,
            // needle: _.compact([searchTitle, searchString]).join(' '),
            limit: self.meta.elementsPerPage || 24,
            offset: self.meta.offset || 0,
            orderBy: self.searchSorting,
            startDate: `<=${self.endDate}`,
            endDate: `>=${self.startDate}`,
            siteHandle: _.get(self.searchCategory, 'slug') || "*",
            uri: `${searchUri}/*`
        };
        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function() {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data.entriesConnection;
            self.searchResults = dataPath.entries;
            self.meta.totalCount = dataPath.totalCount;
            self.meta.totalPages = dataPath.pageInfo.totalPages;
            self.meta.hasPreviousPage = dataPath.pageInfo.hasPreviousPage;
            self.meta.hasNextPage = dataPath.pageInfo.hasNextPage;

            self.sortSearchResults(dataPath.entries);

            if(!self.isReady) {
              self.isReady = true;
            }

            self.isLoading = false;
          });
        }, 500);
      },
      sortSearchResults(results) {
        const self = this;
        const sites = self.sites;

        const sitesBaseUrls = _.map(sites, 'baseUrl');
        let eventDateRanges = {};
        for (let i = 1; i < sitesBaseUrls.length; i += 1) { // Starting from 1 because the first category is for "ALL"
          const baseUrl = sitesBaseUrls[i];
          const siteHandle = sites[i].slug;
          const resultsWithBaseUrl = _.filter(results, function (result) {
            return result.url.match(new RegExp('^' + _.escapeRegExp(baseUrl).replace(/\//g, '\\/') + '\/' + section));
          });
          // Convert results to array of dates
          if(!!resultsWithBaseUrl.length) {
            for(let j = 0; j < resultsWithBaseUrl.length; j += 1) {
              const event = resultsWithBaseUrl[j];
              const eventStartDate = moment(event.startDate, 'X').startOf('day');
              const eventEndDate = moment(event.endDate, 'X').endOf('day');

              do {
                eventDateRanges[eventStartDate.format('YYYY-M-D')] = eventDateRanges[eventStartDate.format('YYYY-M-D')] || [];
                eventDateRanges[eventStartDate.format('YYYY-M-D')].push(siteHandle);
                eventStartDate.add(1, 'days');
              } while (eventStartDate.isSameOrBefore(eventEndDate));
            }
            // sitesArray[siteHandle] = _.uniq(_.flatten(eventDateRanges));
          }
        }

        self.sortedDates = eventDateRanges;
      },
      setHistory: function() {
        var self = this;
        var paramString = '';
        if(!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if(!!_.get(self.searchCategory, 'slug')) {
          paramString += !!paramString ? ('&cat=' + self.searchCategory.slug) : (paramString += '?cat=' + self.searchCategory.slug);
          paramString += !!paramString ? ('&cat-id=' + self.searchCategory.id) : (paramString += '?cat-id=' + self.searchCategory.id);
        }
        if(!!self.meta.currentPage) {
          paramString += !!paramString ? ('&page=' + self.meta.currentPage) : (paramString += '?page=' + self.meta.currentPage);
        }
        if (window.history && window.history.replaceState) {
          var pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          var url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function() {
        // var top = 0;

        // setTimeout(function() {
        //   window.scrollTo(top, 0);
        // }, 100);
        return false;
      },
      formatDate: function(date, format, endDate) {
        const momentDate = moment.unix(date);
        switch (format) {
          case 'time':
            return momentDate.format('HH:mm');
          break;
          case 'day':
            return momentDate.format('dddd');
          break;
          case 'date':
            return momentDate.format('DD.MM.YYYY');
          case 'range':
            if(!!endDate) {
              const momentEndDate = moment.unix(endDate);
              if (momentDate.isSame(momentEndDate, 'day')) {
                return momentDate.format('DD.MM.YYYY');
              } else if (momentDate.isSame(momentEndDate, 'month')) {
                return momentDate.format('DD') + '—' + momentEndDate.format('DD.MM.YYYY');
              } else if (momentDate.isSame(momentEndDate, 'year')) {
                return momentDate.format('DD.MM') + '—' + momentEndDate.format('DD.MM.YYYY');
              }
            } else {
              return momentDate.format('DD.MM.YYYY') + '—';
            }
          break;
        }
      },
    },
  });
};

!!document.getElementById('eventCalendar') && eventCalendar();
