import { t } from '../search/translation';
import compact from 'lodash/compact';

const anchors = document.getElementsByTagName('a');
const siteUrls = new RegExp(compact(MMM.siteUrls).join('|'));
const relative = new RegExp('^http');

for (var i = 0; i < anchors.length; i++) {
    let anchor = anchors[i];
    let href = anchor.getAttribute('href');

    if (
        !siteUrls.test(href) &&
        relative.test(href) &&
        anchor.textContent.trim().length
    ) {
        anchor.insertAdjacentHTML('beforeend', externalLinkIcon());
    };
}

function externalLinkIcon() {
    return `
        <span class="external-link">
            <span class="sr-only">(${t('External link')})</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/>
            </svg>
        </span>
    `;
}
