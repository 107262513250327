<template>
    <div class="flex" @keydown.esc="searchOpen = false">
        <button
            @click="toggleSearch"
            :aria-label="'Open search bar'|t"
            :aria-expanded="searchOpen ? 'true' : 'false'"
            class="p-4 z-20"
            aria-controls="search-bar"
            ref="toggleSearchButton"
        >
            <span v-html="searchIcon"></span>
        </button>
        <div
            id="search-bar"
            :class="{'is-open': searchOpen}"
            class="dropdown-container"
        >
            <div class="h-full flex justify-center items-center">
                <form class="w-full mr-auto" autocomplete="off" role="search" :action="action">
                    <div class="flex items-center py-2">
                        <label for="search-input">
                            <span class="visually-hidden">{{ 'Search'|t }}</span>
                            <span v-html="searchIcon"></span>
                        </label>
                        <input
                            id="search-input"
                            type="text"
                            name="q"
                            :placeholder="'Search'|t"
                            class="small appearance-none bg-transparent border-none w-full text-gray-700 mr-3 h-32 lg:h-48 pl-6 heading-5 heading-5--book shadow-none mb-none"
                            ref="searchInput"
                        >
                        <button type="submit" class="visually-hidden flex-shrink-0 border-transparent button button-small button-green">{{ 'Search'|t }}</button>
                    </div>
                </form>
                <button @click="toggleSearch" :aria-label="'Close search bar'|t">
                    <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="28px" height="28px" viewBox="0 0 512 512" fill="#000000" aria-hidden="true">
                        <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import {t} from '../js/search/translation';
import { store } from '../js/store/header';

export default {
    props: {
        element: {
            type: HTMLElement,
            required: true
        }
    },

    data() {
        return store;
    },

    computed: {
        searchIcon() {
            let svg = this.element.querySelector('svg');
            return svg ? svg.outerHTML : '';
        },

        action() {
            return MMM.siteUrl + 'search';
        }
    },

    filters: {
        t(string, params = {}) {
            return t(string, params);
        }
    },

    methods: {
        toggleSearch() {
            this.searchOpen = !this.searchOpen;
        }
    },

    watch: {
        searchOpen() {
            if (this.searchOpen) {
                this.mobileMenuOpen = false;
            }

            this.$nextTick(() => {
                if (!this.searchOpen && !this.mobileMenuOpen) {
                    this.$refs.toggleSearchButton.focus();
                } else if (this.searchOpen) {
                    this.$refs.searchInput.focus();
                }
            })
        }
    }
}

</script>
