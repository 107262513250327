<template>
  <simplebar
    class="relative"
    :class="isSmallerScreen && 'no-slider'"
    data-simplebar-auto-hide="false"
    ref="container"
    :key="listKey"
    :style="{'height': isSmallerScreen ? 'auto' : calendarHeight + 'px', 'overflow-y': isSmallerScreen ? 'visible' : 'auto'}"
  >
    <div class="absolute w-full h-full pin-x mx-auto flex flex-wrap items-center justify-center" style="z-index: -1">
      <transition name="fadeIn" mode="in-out">
        <div class="loader loader--style8" v-show="isLoading || !isReady" leave-stagger="500">
          <svg version="1.1" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
            </rect>
          </svg>
        </div>
      </transition>
    </div>
    <div :class="isSmallerScreen || !enableScroll ? '0' : 'pr-16'" ref="list" :key="listKey">
      <template v-if="items.length > 0">
        <calendar-search-item
          v-for="(item, key) in items"
          :key="key"
          :item="item"
          :is-loading="isLoading"
          :selected-date="selectedDate"
          :site-group="siteGroup"
        ></calendar-search-item>
      </template>
      <transition name="fadeIn" mode="in-out">
        <template v-if="(!isLoading && items.length === 0) || (!isLoading && !hasEventOnSelectedDay)">
          <h4 class="mb-10 xxl:mb-20 w-full font-sans-medium text-white leading-none">
            {{translations["No results found"]}}
          </h4>
        </template>
      </transition>
    </div>
  </simplebar>
</template>

<script>
import Vue from 'vue';

import simplebar from 'simplebar-vue';

import CalendarSearchItem from './CalendarCard'
import CardLoadingIndicator from './CardLoadingIndicator'

export default {
  name: 'CalendarSearchList',
  props: {
    items: {
      type: Array,
      default: [],
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isReady: {
      type: Boolean,
      default: false
    },
    selectedDate: {
      type: String,
      default: ''
    },
    hasEventOnSelectedDay: {
      type: Boolean,
      default: true
    },
    calendarHeight: {
      type: Number,
      default: 0
    },
    translations: {
      type: Object
    },
    siteGroup: {
      type: String
    }
  },
  data: function() {
    return {
      enableScroll: false,
      listKey: 0
    }
  },
  components: {
    CalendarSearchItem,
    CardLoadingIndicator,
    simplebar
  },
  mounted: function() {
    this.getListHeight();
  },
  updated: function () {
    // this.getListHeight();
  },
  watch: {
    items: function(val, oldVal) {
      this.getListHeight();
    }
  },
  computed: {
    isSmallerScreen: function() {
      return window.innerWidth < 760;
    }
  },
  methods: {
    getListHeight: function() {
      
      this.$nextTick(() => {
        const listHeight = this.$refs.list.offsetHeight;

        if(listHeight > this.calendarHeight) {
          this.enableScroll = true;
          this.$refs.container.SimpleBar.options.forceVisible = true;
        } else {
          this.enableScroll = false;
          this.$refs.container.SimpleBar.options.forceVisible = false;
        }
        this.listKey += 1;
      })
    }
  }
};
</script>

<style lang="scss">
  .loader{
    margin-left: 6px;
    svg path,
    svg rect{
      fill: #ffffff;
    }
  }
</style>