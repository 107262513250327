const zoomDisable = () => {
  const head = document.querySelectorAll('head')[0];
  head.querySelectorAll('meta[name=viewport]')[0].remove();

  let meta = document.createElement("meta");
  meta.name = "viewport";
  meta.content = "width=device-width, initial-scale=1.0, user-scalable=0"

  head.insertBefore(meta, head.firstChild);
}
const zoomEnable = () => {
  const head = document.querySelectorAll('head')[0];
  head.querySelectorAll('meta[name=viewport]')[0].remove();

  let meta = document.createElement("meta");
  meta.name = "viewport";
  meta.content = "width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"

  head.insertBefore(meta, head.firstChild);
}

const inputs = document.querySelectorAll('select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] ');
_.forEach(inputs, function(input){
  input.addEventListener('touchstart', zoomDisable);
  input.addEventListener('touchend', ()=>{
    setTimeout(() => {
      zoomEnable();
    }, 500);
  });
});