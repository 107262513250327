import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import {configureApi, apiToken, apiUrl, getQueryParam, executeQuery} from './searchCommon';
import {translation} from './translation'

import CategorySearchMeta from '../../vue/CategorySearchMeta'
import CategorySearchList from '../../vue/CategorySearchList'
import Pagination from '../../vue/Pagination'
import CategoryFilter from '../../vue/CategoryFilter'

// Site search application
function getcategorySearchLandingData() {
  return {
    allCategories: [],
    sectionType: '',
    siteGroup: '',
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchCategory: {},
    searchCategories: [],
    searchResults: [],
    searchSorting: 'postdate desc',
    meta: {
      totalPages: 1,
      currentPage: 1,
      elementsPerPage: 12,
      totalCount: 0,
      offset: 0
    },
    isLoading: true,
    isReady: false,
  };
}

var filterChangeTimer;

var searchApp = function() {
  const siteGroup = document.getElementById('searchApp').dataset.siteGroup;

// What to search for
const searchSite = document.getElementById('searchApp').dataset.site;
const searchSections = JSON.parse(document.getElementById('searchApp').dataset.section);
const searchEntries = JSON.parse(document.getElementById('searchApp').dataset.entries);
const searchTransform = JSON.parse(document.getElementById('searchApp').dataset.transform);

let searchQueries = ''
_.each(searchEntries, (entryType) => {
  searchQueries = searchQueries + 
      `
      ... on ${entryType} {
        id
        title
        url
        image {
          ...on GeneralVolume {
            url
            title
            imageOptimize {
              ...on OptimizedImagesData {
                optimizedImageUrls
                placeholder
              }
            }
          }
        }
        postDate${entryType === 'NewsArticle' ? `
        newsCategory {
          title
        }
        ` : entryType === 'BlogPostsArticle' ? `
        blogCategory {
          title
        }
        ` : ''}
        ${searchTransform.join('\n')}
      }`
});

// The query to search for entries in Craft
const searchQuery =
  `
  query searchQuery($sections: [SectionsEnum], $needle: String, $limit: Int, $offset: Int, $orderBy: String, $site: String)
    {
      entriesConnection( limit: $limit offset: $offset orderBy: $orderBy search: $needle section: $sections site: $site) {
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          currentPage
          totalPages
          first
          last
        }
        entries{
          ${searchQueries}
        }
      }
      ${searchSections.length === 1 && searchSections[0] === 'news' ? `
      meta: entriesConnection(search: $needle, section: $sections, site: $site) {
        entries{
          ... on NewsArticle {
            newsCategory {
              title
            }
          }
        }
      }
      ` : searchSections.length === 1 && searchSections[0] === 'blogPosts' ? `
      meta: entriesConnection(search: $needle, section: $sections, site: $site) {
        entries{
          ... on BlogPostsArticle {
            blogCategory {
              title
            }
          }
        }
      }
      ` : ''}
    }
  `;

  new Vue({
    el: document.getElementById('searchApp'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      CategorySearchList,
      CategorySearchMeta,
      Pagination,
      CategoryFilter,
    },
    beforeCreate: function() {
    },
    created: function() {
      // this.selected.location = !!$eventLanding.data('location') && $eventLanding.data('location');

      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if(!!categoryParam) {
        // this.searchCategory.slug = categoryParam;
      }
      if(!!categoryIdParam) {
        this.searchCategories = categoryIdParam;
      }
      if(!!pageParam) {
        this.meta.currentPage = parseInt(pageParam);
      }
      this.sectionType = searchSections;
      this.siteGroup = siteGroup;
    },
    mounted: function() {
      this.performSearch();
      this.focusStyle();
    },
    updated: function() {
    },
    destroyed: function destroyed() {
    },
    watch: {
      'meta.currentPage': function(val) {
        this.scrollup();
        this.meta.offset = this.meta.elementsPerPage * (val - 1);
        this.performSearch();
      },
      searchQuery: function(val, oldVal) {
        if(!!oldVal && val !== oldVal) {
          this.meta = _.get(getcategorySearchLandingData(), 'meta');
        }
        this.performSearch();
      },
      searchCategory: {
        handler: function(val, oldVal) {
          if(!!oldVal && val.id !== oldVal.id) {
            this.meta = _.get(getcategorySearchLandingData(), 'meta');
          }
          this.performSearch();
        },
        deep: true
      },
      searchCategories: {
        handler: function(val, oldVal) {
          if(!!oldVal && JSON.stringify(val) !== JSON.stringify(oldVal)) {
            this.meta = _.get(getcategorySearchLandingData(), 'meta');
          }
          this.performSearch();
        },
        deep: true
      },
      searchSorting: function() {
        this.scrollup();
        this.performSearch();
      },
    },
    filters: {
      t: function(val) {
        const locale = document.getElementById('searchApp').dataset.locale || 'fi-FI';
        const trans = translation[locale];
        return trans[val] || val;
      },
    },
    computed: {
    },
    methods: {
      focusStyle() {
        // Focus style
        const elements = this.$el.querySelectorAll("a, button, input[type='button'], select");
        let mouseDown = false;

        for (let i = 0; i < elements.length; i++) {
          elements[i].addEventListener('mousedown', () => {
            mouseDown = true;
          });
          
          elements[i].addEventListener('mouseup', () => {
            mouseDown = false;
          });
          
          elements[i].addEventListener('focus', (event) => {
            if (mouseDown) {
              event.target.classList.add('mouseDown');
            }
          });
          elements[i].addEventListener('blur', (event) => {
            event.target.classList.remove('mouseDown');
          });
        }
      },
      performSearch() {
        let self = this;

        self.isLoading = true;
        self.setHistory();

        let searchTitle = !!self.searchQuery ? `title:*${self.searchQuery}*` : '';

        let searchCategoriesString = '';
        if (!_.isEmpty(self.searchCategories)) {
          _.map(self.searchCategories, o => {
            searchCategoriesString += (`:"*${o.title}*" OR ::"*${o.title}*" `)
          })
        } else {
          searchCategoriesString = '';
        }
        // const startDateString = !_.isEmpty(_.get(self, 'startDate')) ? `and, >= ${moment(self.startDate.start).format('YYYY-MM-DD')}, <= ${moment(self.startDate.end).format('YYYY-MM-DD')}` : '';

        // Set the variables we will pass in to our query
        let variables = {
            sections: searchSections,
            needle: _.compact([searchTitle, searchCategoriesString]).join(' '),
            // startDate: startDateString,
            limit: self.meta.elementsPerPage || 24,
            offset: self.meta.offset || 0,
            orderBy: self.searchSorting,
            site: searchSite
        };

        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function() {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data.entriesConnection;
            self.searchResults = dataPath.entries;
            self.meta.totalCount = dataPath.totalCount;
            self.meta.totalPages = dataPath.pageInfo.totalPages;
            self.meta.hasPreviousPage = dataPath.pageInfo.hasPreviousPage;
            self.meta.hasNextPage = dataPath.pageInfo.hasNextPage;
            if (!!data.data.meta) {
              self.allCategories = _.uniq(_.map(_.merge(_.flatMap(data.data.meta.entries, 'newsCategory'), _.flatMap(data.data.meta.entries, 'blogCategory')), 'title'));
            }
            if(!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function() {
        let self = this;
        let paramString = '';
        if(!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if(!!_.get(self.searchCategories[0], 'slug')) {
          _.forEach(self.searchCategories, o => {
            paramString += !!paramString ? ('&cat=' + o.slug) : (paramString += '?cat=' + o.slug);
            paramString += !!paramString ? ('&cat-id=' + o.id) : (paramString += '?cat-id=' + o.id);
          })
        }
        if(!_.isEmpty(self.startDate)) {
          paramString += !!paramString ? ('&start=' + moment(self.startDate.start).format('YYYY-MM-DD')) : (paramString += '?start=' + moment(self.startDate.start).format('YYYY-MM-DD'));
          paramString += !!paramString ? ('&end=' + moment(self.startDate.end).format('YYYY-MM-DD')) : (paramString += '?end=' + moment(self.startDate.end).format('YYYY-MM-DD'));
        }
        if(!!self.meta.currentPage) {
          paramString += !!paramString ? ('&page=' + self.meta.currentPage) : (paramString += '?page=' + self.meta.currentPage);
        }
        if (window.history && window.history.replaceState) {
          let pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          let url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function() {
        const element = document.getElementById('search-results')
        const offset = 200
        const bodyRect = document.body.getBoundingClientRect().top
        const elementRect = element.getBoundingClientRect().top
        const elementPosition = elementRect - bodyRect
        const offsetPosition = elementPosition - offset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      },
    },
  });
};

!!document.getElementById('searchApp') && searchApp();
