<template>
  <transition name="fadeIn" mode="in-out">
    <div class="cardLoader mb-4 pb-4 flex flex-wrap flex-row border-b border-1 border-solid border-grey" v-show="isLoading || !isReady" leave-stagger="500"> <!-- v-show="isLoading || !isReady" leave-stagger="500" -->
      <div class="w-1/2" v-for="n in 2" :key="n">
        <div class="flex flex-col lg:flex-row -mx-3">
          <div class="flex flex-row lg:items-top w-full px-3">
            <div class="hidden md:flex flex-col h-full w-2/5 mr-5">
              <div class="block relative object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5">
                <div class="cardLoader__image absolute pin-t pin-l w-full h-full block object-cover"></div>
              </div>
            </div>
            <div class="w-full flex flex-col pr-3">
              <div class="pb-1">
                <span class="pr-1 mt-2 flex align-center text-blue">
                  <div class="line-micro flex items-center"></div>
                </span>
              </div>
              <h3 class="line-medium-large heading-3 mb-none text-ebonyClay lg:hover:text-grey mt-1-5"></h3>
              <div class="mt-auto">
                <div class="line-medium mt-2 flex items-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'searchCardLoadingIndicator',
  props: ['isLoading', 'isReady']
};
</script>
