<template>
    <div>
        <div class="flex flex-row flex-wrap items-center mx-5 xxl:mx-10 p-16 bg-white shadow rounded">
            <button class="flex-initial event-calendar-previous-month" @click="handlePrevMonthClick" :aria-label="'Go to {month}'|t({month: prevMonthName})"></button>
            <h2 class="flex-auto text-center capitalize">{{ currentMonth }} {{ currentYear }}</h2>
            <button class="flex-initial event-calendar-next-month" @click="handleNextMonthClick" :aria-label="'Go to {month}'|t({month: nextMonthName})"></button>
        </div>
        <div role="status" class="px-5 xxl:px-10 my-4 text-center">
            <span class="bg-white p-5 my-8 block" v-if="!isLoading && isReady && !searchResults.length">
                {{ 'No events found for {month} {year}.'|t({month: currentMonth, year: currentYear}) }}
            </span>
            <span class="sr-only" v-if="!isLoading && isReady && searchResults.length">
                {{ '{amount} events found'|t({amount: searchResults.length}) }}
            </span>
            <span v-if="isLoading">
                <span class="event-calendar-loading-indicator">
                    <span></span><span></span><span></span><span></span>
                </span>
                <span class="sr-only">{{ 'Loading {month}'|t({month: currentMonth}) }}</span>
            </span>
        </div>
        <ul v-if="searchResults.length && !isLoading" class="flex flex-row flex-wrap">
            <li v-for="result in searchResults" class="w-full flex md:w-1/2 lg:w-1/3 px-5 xxl:px-10 my-8">
                <article class="flex flex-row flex-wrap bg-white card cursor-pointer">
                    <figure v-if="result.image.length" class="relative w-full pb-15/24">
                        <img class="absolute w-full h-full object-fit" :src="result.image[0].url" alt="">
                    </figure>
                    <div class="px-12 pb-8 pt-12 flex flex-col flex-wrap justify-start">
                        <div class="mb-8">
                            <span class="sr-only">{{ 'Keywords'|t }}:</span>
                            <ul v-for="eventCategory in result.eventCategory">
                                <li>{{ eventCategory.title }}</li>
                            </ul>
                        </div>
                        <h2 class="heading-4 mb-8">
                            <a :href="result.url">{{ result.title }}</a>
                        </h2>
                        <p class="mt-auto small-text">
                            <span class="sr-only">{{ 'Event starts at'|t }}:</span>
                            <time :datetime="result.startDate|datetime">{{ result.startDate|formatDate }}</time>
                        </p>
                    </div>
                </article>
            </li>
        </ul>
    </div>
</template>

<script>

import { t } from '../js/search/translation';
import handleCardClick from '../js/components/cards';
import { getcategorySearchLandingData } from '../js/search/eventCalendar';

export default {
    data() {
        return getcategorySearchLandingData;
    },

    computed: {
        currentMonth() {
            return this.currentDate.toLocaleString(MMM.language, {month: 'long'});
        },

        currentYear() {
            return this.currentDate.getFullYear();
        },

        prevMonth() {
            return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1);
        },

        prevMonthName() {
            return this.prevMonth.toLocaleString(MMM.language, {month: 'long'});
        },

        nextMonth() {
            return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1);
        },

        nextMonthName() {
            return this.nextMonth.toLocaleString(MMM.language, {month: 'long'});
        }
    },

    filters: {
        t(string, parameters = {}) {
            return t(string, parameters);
        },

        formatDate(date) {
            let formattedDate = new Date(date * 1000)
                .toLocaleString(MMM.language, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute:'2-digit'
                });

            return formattedDate;
        },

        datetime(date) {
            let formattedDate = new Date(date * 1000)
                .toISOString();

            return formattedDate.split('T')[0];
        }
    },

    methods: {
        handlePrevMonthClick() {
            this.currentDate = this.prevMonth;
        },

        handleNextMonthClick() {
            this.currentDate = this.nextMonth;
        }
    },

    watch: {
        searchResults() {
            this.$nextTick(() => {
                const cards = this.$el.querySelectorAll('.card');
                handleCardClick(cards);
            });
        },

        currentDate() {
            let startDate = this.currentDate.getTime();
            let endDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1).getTime();

            this.endDate = endDate /1000;
            this.startDate = startDate / 1000;
        }
    }
}

</script>

<style lang="scss" scoped>

$month-button-size: 1.25rem;
$month-button-background-color: #ffffff;
$month-button-border-color: #adadad;
$month-button-arrow-color: #696c72;

.event-calendar-previous-month {
    font-size: $month-button-size;
    height: 2em;
    width: 2em;
    background: $month-button-background-color;
    box-shadow: 0 0 0px 1px $month-button-border-color;
    border-radius: 9999px;
    position: relative;
    transform: rotate(135deg);

    &::after {
        content: "";
        height: .625em;
        width: .625em;
        border-right: 3px solid;
        border-bottom: 3px solid;
        border-color: $month-button-arrow-color;
        display: block;
        position: absolute;
        top: .625em;
        left: .625em;
    }
}

.event-calendar-next-month {
    font-size: $month-button-size;
    height: 2em;
    width: 2em;
    background: $month-button-background-color;
    box-shadow: 0 0 0px 1px $month-button-border-color;
    border-radius: 9999px;
    position: relative;
    transform: rotate(-45deg);

    &::after {
        content: "";
        height: .625em;
        width: .625em;
        border-right: 3px solid;
        border-bottom: 3px solid;
        border-color: $month-button-arrow-color;
        display: block;
        position: absolute;
        top: .625em;
        left: .625em;
    }
}

.event-calendar-loading-indicator {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    span {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: loading-indicator 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }

    span:nth-child(1) {
        animation-delay: -0.45s;
    }

    span:nth-child(2) {
        animation-delay: -0.3s;
    }

    span:nth-child(3) {
        animation-delay: -0.15s;
    }
}

@keyframes loading-indicator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

</style>
