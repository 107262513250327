// We import all other js libraries
import Vue from 'vue'
import PrimaryNavigation from '../../vue/PrimaryNavigation'

let primaryNavigation = document.getElementById('primary-navigation');

if (primaryNavigation) {
    new Vue({
        el: primaryNavigation,
        components: { PrimaryNavigation },
        render: function(createElement) {
            return createElement(PrimaryNavigation, {
                props: {
                    element: primaryNavigation,
                }
            });
        }
    })
}

// if(!!document.getElementById("header")) {

//   Vue.directive('click-outside', {
//     bind: function (el, binding, vnode) {
//       el.clickOutsideEvent = function (event) {
//         // here I check that click was outside the el and his childrens
//         if (!(el == event.target || el.contains(event.target))) {
//           // and if it did, call method provided in attribute value
//           vnode.context[binding.expression](event);
//         }
//       };
//       document.body.addEventListener('click', el.clickOutsideEvent)
//     },
//     unbind: function (el) {
//       document.body.removeEventListener('click', el.clickOutsideEvent)
//     },
//   });

//   // Primary Navigation
//   const primaryNavigation = new Vue({
//     name: 'primary-navigation',
//     el: '#primary-navigation',
//     data: {
//       open: false,
//       searchIsActive: false,
//       isKeydown: false
//     },
//     created() {
//       window.addEventListener('scroll', this.handleScroll)
//     },
//     destroyed() {
//       window.removeEventListener('scroll', this.handleScroll)
//     },
//     mounted() {
//       window.addEventListener('keydown', this.handleKeyDown);
//     },
//     watch: {
//       "searchIsActive": function(val, oldVal) {
//         if(val !== oldVal && !!val) {
//           setTimeout(() => {
//             this.$refs.searchInput.focus()
//           }, 300);
//         }
//       },
//     },
//     methods: {
//       toggleNavigation() {
//         if(!this.open) {
//           this.searchIsActive = false
//         }
//         this.open = !this.open
//       },
//       closeNavigation() {
//         this.open = false;
//       },
//       toggleSearch() {
//         if(!this.searchIsActive) {
//           this.open = false
//           setTimeout(() => {
//             this.searchIsActive = true
//           }, 300);
//         } else {
//           this.searchIsActive = false
//         }
//       },
//       focusHamburger() {
//         this.$refs.hamburger.focus();
//       },
//       handleKeyDown(event) {
//         if ((event.key == 'Escape' || event.key == 'Esc')) {
//           if (this.open) {
//             this.open = !this.open;
//           }
//           if (this.searchIsActive) {
//             this.searchIsActive = !this.searchIsActive;
//           }
//         }
//       },
//     },
//   })
// }
