// CSS object-fit fallback on Edge (and other browsers)
// Read more, https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3

// Detect objectFit support
if ('objectFit' in document.documentElement.style === false) {
  document.body.classList.add('no-object-fit')

  // assign HTMLCollection with parents of images with objectFit to variable
  const container = document.getElementsByClassName('object-fit-fallback')

  // Loop through HTMLCollection
  for (let i = 0; i < container.length; i++) {
    // Asign image source to variable

    let imageSource = container[i].querySelector('img').src

    if (
      container[i].querySelector('img').getAttribute('data-srcset') !== null
    ) {
      const firstSrcSet = container[i]
        .querySelector('img')
        .getAttribute('data-srcset')
        .split(', ')[0]
      imageSource = firstSrcSet.split(' ')[0]
    }

    // Hide image
    container[i].querySelector('img').style.display = 'none'

    // Add background-size: cover
    container[i].style.backgroundSize = 'cover'

    // Add background-image: and put image source here
    container[i].style.backgroundImage = 'url(' + imageSource + ')'

    // Add background-position: center center
    container[i].style.backgroundPosition = 'center center'
  }
} else {
  // You don't have to worry
  // console.log('No worries, your browser supports objectFit')
}
