import axios from 'axios'
import serialize from '../helpers/serializer'

// Get data by serializing the form
const forms = document.getElementsByClassName('subscribe-form');
const formSubmitButtons = document.getElementsByClassName('subscribe-form__button');

if (!!forms) {
  for (let form of forms) {
    const formInputBox = form.querySelector('.subscribe-form__input');
    const gdprText = form.querySelector('.subscribe-form__gdpr');

    // Form submit
    form.addEventListener('submit', function (event) {
      event.preventDefault();
      const data = serialize(form);
      for (let formSubmitButton of formSubmitButtons) {
        formSubmitButton.setAttribute('disabled', 'disabled');
        formSubmitButton.innerHTML = 'Sending';
      }
      // Post the data to the current URL
      axios.post('', data)
        .then(function (response) {
          // console.log('response: ', response);
          const data = response.data;
          if (data.success) {
            for (let formSubmitButton of formSubmitButtons) {
              formSubmitButton.innerHTML = 'Kiitos';
            }
            // console.log("Success");
          } else {
            for (let formSubmitButton of formSubmitButtons) {
              formSubmitButton.removeAttribute('disabled');
              formSubmitButton.innerHTML = 'Tilaa';
            }
            // console.log("Error has occured");
          }
        })
        .catch(function (error) {
          for (let formSubmitButton of formSubmitButtons) {
            formSubmitButton.removeAttribute('disabled');
            formSubmitButton.innerHTML = 'Tilaa';
          }
          console.log('error', error);
        });
    });
  };
}
