import _ from 'lodash'

const checkboxes = document.querySelectorAll('form [type="checkbox"]');
let labels = [];
_.forEach(checkboxes, function(item) {
  if (item.parentNode.tagName === 'LABEL') {
    labels = [...labels, item.parentNode];
  }
});

_.forEach(labels, function(label, i) {
  const labelText = label.childNodes[label.childNodes.length - 1];
  const input = label.querySelector('input[type="checkbox"]');

  // Create an empty dummy span and add class and content string as inner HTML
  const labelSpan = document.createElement('span');
  labelSpan.innerHTML = labelText.textContent;
  labelSpan.className = 'label-text';

  // Replace inner html of label with new span
  labelText.remove();
  input.after(labelSpan);

  label.classList.add('styled-checkbox');

  const dummySpan = document.createElement('span');
  dummySpan.className = 'dummy';
  input.after(dummySpan);
});

const isError = (event) => {
  if(event.target.validity.valid) {
    event.target.closest('.freeform-column').classList.remove('is-error');
  } else {
    event.target.closest('.freeform-column').classList.add('is-error');
  }
}

const requiredFormFields = document.getElementsByClassName('freeform-required');
_.forEach(requiredFormFields, function(field) {
  let input;
  if( field.classList.contains('freeform-input-only-label') ) {
    input = label.querySelector('input[type="checkbox"]');
    // input = field.childNodes[1];
    input.addEventListener('change', isError, false);
  } else {
    input = field.nextElementSibling;
    input.addEventListener('blur', isError, false);
  }
  input.required = true;
  input.addEventListener('invalid', isError, false);
});

const zoomDisable = () => {
  const head = document.querySelectorAll('head')[0];
  head.querySelectorAll('meta[name=viewport]')[0].remove();

  let meta = document.createElement("meta");
  meta.name = "viewport";
  meta.content = "width=device-width, initial-scale=1.0, user-scalable=0"

  head.insertBefore(meta, head.firstChild);
}
const zoomEnable = () => {
  const head = document.querySelectorAll('head')[0];
  head.querySelectorAll('meta[name=viewport]')[0].remove();

  let meta = document.createElement("meta");
  meta.name = "viewport";
  meta.content = "width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"

  head.insertBefore(meta, head.firstChild);
}

const inputs = document.querySelectorAll('select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] ');
_.forEach(inputs, function(input){
  input.addEventListener('touchstart', zoomDisable);
  input.addEventListener('touchend', ()=>{
    setTimeout(() => {
      zoomEnable();
    }, 500);
  });
});