<template>
  <ul class="relative -mx-5 xxl:-mx-10 flex flex-wrap" :style="{ minHeight: isLoading ? listMinHeight() : 'auto' }">
    <li v-if="isLoading && items.length === 0" class="absolute w-full h-full pin-x mx-auto flex flex-wrap content-start" style="z-index: -1">
      <card-loading-indicator
        v-for="n in 12"
        :key="n"
        :is-loading="isLoading"
        :is-ready="isReady"
        :section-type="sectionType"
      ></card-loading-indicator>
    </li>
    <template v-if="items.length > 0">
      <category-search-item
        v-for="(item, key) in items"
        :key="key"
        :item="item"
        :categories="!!item.newsCategory ? item.newsCategory : !!item.blogCategory ? item.blogCategory : null"
        :is-loading="isLoading"
        :section-type="sectionType"
        :site-group="siteGroup"
      ></category-search-item>
    </template>
    <transition name="fadeIn" mode="in-out">
      <template v-if="!isLoading && items.length === 0">
        <h4 class="heading-4 mx-auto py-8 text-center">{{"No results found"|t}}</h4>
      </template>
    </transition>
  </ul>
</template>
<script>
import Vue from 'vue';
import {translation} from '../js/search/translation'
import CategorySearchItem from './CategorySearchItem'
import CardLoadingIndicator from './CardLoadingIndicator'
import handleCardClick from '../js/components/cards'

export default {
  name: 'CategorySearchList',
  props: {
    sectionType: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: [],
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isReady: {
      type: Boolean,
      default: false
    },
    siteGroup: {
      type: String
    }
  },
  components: {
    CategorySearchItem,
    CardLoadingIndicator,
  },
  filters: {
    t(val) {
      const locale = document.getElementById('searchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  methods: {
    listMinHeight: function() {
      let rowHeight = 310;
      let rowAmount = 2;
      // if ( window.innerWidth < 480 ) {
      //   rowHeight = this.sectionType[0] === 'events' ? 280 : 340;
      //   rowAmount = this.sectionType[0] === 'events' ? 3 : 4;
      // } else if ( window.innerWidth < 780 ) {
      //   rowHeight = this.sectionType[0] === 'events' ? 280 : 240;
      //   rowAmount = this.sectionType[0] === 'events' ? 3 : 8/2;
      // }
      return rowAmount * rowHeight + 'px';
    }
  },
  watch: {
    items() {
      this.$nextTick(() => {
        const cards = this.$el.querySelectorAll('.card');
        handleCardClick(cards);
      })
    }
  }
};
</script>
