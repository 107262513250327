<template>
  <transition name="fadeIn" mode="in-out">
    <li class="w-full md:w-6/12 mb-10 xxl:mb-20 px-5 xxl:px-10" v-show="!isLoading">
      <a class="block h-full" :href="item.url" :title="item.title">
        <div class="pb-10 xxl:pb-20 w-full h-full flex flex-col xxl:flex-row xxl:items-center border-b border-gallery border-1 border-solid">
          <div class="w-full xxl:w-4/12 xxl:pr-5" v-if="!!item.image && !!item.image[0]">
            <figure class="mb-8 xxl:mb-0 relative z-10 object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5">
              <img
                class="absolute top-0 left-0 z-0 w-full h-full block object-cover lazyload"
                :src="!!item.image && !!item.image[0] ? item.image[0].imageOptimize.placeholder : '/assets/img/placeholder.jpg'"
                :data-srcset="!!item.image && !!item.image[0] ? item.image[0].imageOptimize.optimizedImageUrls.join(', ') : '/assets/img/placeholder.jpg'"
                data-sizes="auto"
                alt=""
                onerror="this.src = '/assets/img/placeholder.jpg';
                  this['data-srcset'] = '/assets/img/placeholder.jpg'
                  this['srcset'] = '/assets/img/placeholder.jpg'">
              <div class="absolute top-0 left-0 z-10 w-full h-full bg-black opacity-0 hover:opacity-10"></div>
            </figure>
          </div>
          <div class="w-full xxl:w-8/12 xxl:pl-5">
            <!-- <span class="extra-small-text text-ebonyClay mb-8" v-if="!!item.section">{{ item.section.name }}</span> -->
              <h3 class="heading-5 mb-none pb-16 text-ebonyClay hover:opacity-50">
                {{!!item.title && item.title.length > 117 ? item.title.slice(0, 114) + '...' : item.title}}
              </h3>
            <div v-if="!!item.categories || (!!item.section && item.section.name === 'News')" class="extra-small-text text-ebonyClay">
              <span v-if="item.section.name === 'News'">
                {{ item.postDate | unixToIso }}
                <span v-if="item.categories.length" class="px-1">|</span>
              </span>
              <template v-if="!!item.categories">
                <span class="extra-small-text opacity-50">{{_.map(item.categories.slice(0,2), 'title').join(', ')}}</span>
              </template>
            </div>
          </div>
        </div>
      </a>
    </li>
  </transition>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

// moment.locale('sv');

export default {
  name: 'SearchPageItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    }
  },
  mounted: function() {
    this.focusStyle();
  },
  beforeDestroy: function() {
    this.removeFocusStyle();
  },
  filters: {
    unixToIso: function(unix_timestamp) {
      return moment.unix(unix_timestamp).format('DD MMM YYYY');
    }
  },
  computed: {
    _(){
      return _;
    }
  },
  methods: {
    formatDate: function(date, format) {
      const momentDate = moment.unix(date);

      switch (format) {
        case 'time':
          return momentDate.format('HH:mm');
        break;
        case 'day':
          return momentDate.format('dddd');
        break;
        case 'date':
          return momentDate.format('DD.MM');
        break;
      }
    },
    focusStyle() {
      // Focus style
      const elements = this.$el.querySelectorAll("a, button, input[type='button'], select");
      let mouseDown = false;

      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('mousedown', () => {
          mouseDown = true;
        });

        elements[i].addEventListener('mouseup', () => {
          mouseDown = false;
        });

        elements[i].addEventListener('focus', (event) => {
          if (mouseDown) {
            event.target.classList.add('mouseDown');
          }
        });
        elements[i].addEventListener('blur', (event) => {
          event.target.classList.remove('mouseDown');
        });
      }
    },
    removeFocusStyle() {
      // Focus style
      const elements = this.$el.querySelectorAll("a, button, input[type='button'], select");
      let mouseDown = false;

      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('mousedown', () => {
          mouseDown = true;
        });

        elements[i].removeEventListener('mouseup', () => {
          mouseDown = false;
        });

        elements[i].removeEventListener('focus', (event) => {
          if (mouseDown) {
            event.target.classList.add('mouseDown');
          }
        });
        elements[i].removeEventListener('blur', (event) => {
          event.target.classList.remove('mouseDown');
        });
      }
    }
  }
};
</script>
