import Flickity from 'flickity';
import _ from 'lodash';

const carouselContainers = document.querySelectorAll('[data-carousel-container]');
const carousel = () => {
  _.forEach(carouselContainers, function(carouselContainer) {
    const carousel = carouselContainer.querySelectorAll('[data-carousel]')[0];
    const carouselBounding = carousel.getBoundingClientRect();
    const carouselAmount = carousel.getAttribute('data-carousel');
    const carouselItems = carousel.querySelectorAll('[data-carousel-item]');
    const carouselPrevButton = carouselContainer.querySelectorAll('[data-carousel-prev]')[0];
    const carouselNextButton = carouselContainer.querySelectorAll('[data-carousel-next]')[0];
    const isInCarousel = (elem) => {
      const bounding = elem.getBoundingClientRect();
      return (
          bounding.left >= carouselBounding.left - 10 &&
          bounding.right <= (carouselBounding.left + carouselBounding.width) + 10
      );
    };
    const applyClasses = () => {
      _.forEach(carouselItems, function(carouselItem) {
        if(isInCarousel(carouselItem)) {
          carouselItem.classList.remove('overlay-40');
        } else {
          carouselItem.classList.add('overlay-40');
        }
      });
    }

    const flkty = new Flickity( carousel, {
      pageDots: false,
      prevNextButtons: false,
      cellAlign: 'left',
      contain: true,
      touchVerticalScroll: false,
      draggable: true,
      setGallerySize: true,
      resize: true,
      on: {
        ready: function() {
          applyClasses();
        },
        settle: function() {
          applyClasses();
        }
      }
    });

    carouselPrevButton.addEventListener('click', function(event) {
      if (flkty.selectedIndex > 0) {
        const lastItemIndex = parseInt(flkty.selectedIndex) + parseInt(carouselAmount) - 1;
        carouselItems[flkty.selectedIndex - 1] && carouselItems[flkty.selectedIndex - 1].classList.remove('overlay-40');
        carouselItems[lastItemIndex] && carouselItems[lastItemIndex].classList.add('overlay-40');
        flkty.previous(true);
      }
    });
    carouselNextButton.addEventListener('click', function(event) {
      if (flkty.selectedIndex < flkty.slides.length - carouselAmount) {
        const lastItemIndex = parseInt(flkty.selectedIndex) + parseInt(carouselAmount);
        carouselItems[flkty.selectedIndex] && carouselItems[flkty.selectedIndex].classList.add('overlay-40');
        carouselItems[lastItemIndex] && carouselItems[lastItemIndex].classList.remove('overlay-40');
        flkty.next(true);
      }
    });
  });
}

if( !!carouselContainers ) {
  carousel();
  window.addEventListener("resize", function(){
    carousel();
  });
}