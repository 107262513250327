import Vue from 'vue'
import SearchLink from '../../vue/SearchLink'

let searchLink = document.getElementById('search-link');

if (searchLink) {
    new Vue({
        el: searchLink,
        components: { SearchLink },
        render: function(createElement) {
            return createElement(SearchLink, {
                props: {
                    element: searchLink,
                }
            });
        }
    })
}
